<template>
    <main class="justify-center mx-auto mt-3 mb-4 sm:w-4/5 md:w-3/4 lg:w-4/6 overflow-hidden">

        <div class="pt-6 w-full shadow-2xl">

            <div class="card-body pt-0">

                <form @submit.prevent="uploadImage">

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Subject</span>
                        </label>
                        <input type="text" placeholder="Write a title..." class="input input-accent"
                            v-model="form.title">
                        <small class="text-red-600" v-if="errors.title">{{ errors.title[0] }}</small>
                    </div>
                    
                    <div class="form-control mt-3">
                        <label class="label">
                            <span class="label-text font-bold">Body :</span>
                        </label>
                        <textarea class="textarea h-24 textarea-bordered textarea-accent"
                            placeholder="Write a description about the status" v-model="form.description"></textarea>
                        <small class="text-red-600" v-if="errors.description">{{ errors.description[0] }}</small>
                    </div>

                    <div>
                        <div class="form-control mt-2">
                            <label class="label">
                                <span class="label-text font-bold">Select Message Image/Icon :</span>
                            </label>
                            <div class="mx-2">
                                <input type="file" ref="select" accept=".jpg,.png,.jpeg" class="file"
                                    @change="imageChange">
                                <small class="text-red-600" v-if="errors.image">{{ errors.image[0] }}</small>
                            </div>
                        </div>

                        <div class="form-control mt-5" v-if="form.image">
                            <label class="label">
                                <span class="label-text font-bold">Preview :</span>
                            </label>
                            <div class="flex flex-wrap justify-center">
                                <div class="w-8/12 sm:w-6/12 px-4">
                                    <img :src="form.image" alt="..."
                                        class="rounded max-w-full h-auto align-middle border-none" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Message Label :</span>
                        </label>
                        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                            <div class="truncate" v-for="category in categories" :key="category.id">
                                <label class="inline-flex items-center">
                                    <input type="checkbox" checked="checked" class="checkbox checkbox-accent"
                                        :value="category.id" v-model="form.categories">
                                    <span class="label-text ml-1 md:ml-2">{{ category.name }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="text-red-600" v-if="errors.categories">{{ errors.categories[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Languages :</span>
                        </label>
                        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                            <div class="truncate" v-for="language in languages" :key="language.id">
                                <label class="inline-flex items-center">
                                    <input type="checkbox" checked="checked" class="checkbox checkbox-accent"
                                        :value="language.id" v-model="form.languages">
                                    <span class="label-text ml-1 md:ml-2">{{ language.name }}</span>
                                </label>
                            </div>
                        </div>
                        <small class="text-red-600" v-if="errors.languages">{{ errors.languages[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Expected Opens : </span>
                        </label>
                        <input type="number" placeholder="" class="input input-accent" v-model="form.expected_opens">
                        <small class="text-red-600" v-if="errors.expected_opens">{{ errors.expected_opens[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Credit Per Opens (Minimum required 1) : </span>
                        </label>
                        <input type="number" placeholder="" class="input input-accent" v-model="form.credit_click">
                        <small class="text-red-600" v-if="errors.credit_click">{{ errors.credit_click[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Publication Status : </span>
                        </label>
                        <select class="select select-accent" v-model="form.public_status">
                            <option value="select one" disabled selected="selected">Select One</option>
                            <option value="send">Send</option>
                            <option value="default">Default</option>
                        </select>
                        <small class="text-red-600" v-if="errors.public_status">{{ errors.public_status[0] }}</small>
                    </div>

                    <div class="mt-4 p-6" v-if="uploading">
                        <progress class="progress progress-accent" :value="uploadPercentage" max="100"></progress>
                    </div>

                    <div class="form-control mt-8 mb-3" :class="uploading ? 'disabledArea' : ''">
                        <button type="submit" class="btn btn-primary">
                            <svg-loading height="16px" width="16px" class="mr-1" v-if="uploading" />
                            Send Message
                        </button>
                    </div>
                </form>
            </div>

        </div>

    </main>
</template>

<script>
import axios from "axios";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + ' | ' + this.$sitename;
    },
    created() {
        this.getUploadInfo();
    },
    mounted() {
        this.$Progress.finish();
    },
    data() {
        return {
            form: {
                title: null,
                description: null,
                image: null,
                categories: [],
                languages: [],
                expected_opens: null,
                credit_click: null,
                public_status: "select one",
            },
            uploading: false,
            uploadPercentage: 0,
            errors: {},
            categories: [],
            languages: [],
        }
    },
    methods: {
        getUploadInfo() {
            axios.get('/admin/upload-info')
                .then(({ data }) => {
                    this.categories = data.categories;
                    this.languages = data.languages;
                })
                .catch((error) => {
                    console.log('Something went wrong')
                })
        },
        uploadImage() {
            this.uploading = true;
            axios.post('/admin/upload-image', this.form, {
                onUploadProgress: function (progressEvent) {
                    this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(this)
            })
                .then(({ data }) => {
                    this.uploading = false;
                    this.uploadPercentage = 0;
                    this.form = { title: null, description: null, image: null, categories: [], languages: [], expected_opens: null, credit_click: null, public_status: "select one", };
                    this.getUploadInfo();
                    this.$refs.select.value = null;
                    this.$noti('success', '' + data.message + '')
                })
                .catch((error) => {
                    this.uploading = false;
                    this.uploadPercentage = 0;
                    this.errors = error.response.data.errors;
                    this.$noti('error', 'Something Went Wrong')
                })
        },
        imageChange(event) {
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = event => {
                this.form.image = event.target.result
            };
            reader.readAsDataURL(file);
        },
    },
}
</script>

<style scoped>
</style>