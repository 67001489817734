<template>
  <main class="justify-center mx-auto w-full shadow-2xl p-2 bg-base-100 mx-1">
    <div class="mx-2 mt-3">
      <div class="tabs tabs-boxed">
        <div :class="formType === 1 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 1">General</div>
        <div :class="formType === 2 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 2">Earnings</div>
        <div :class="formType === 3 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 3">SMTP</div>
        <div :class="formType === 4 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 4">Payments</div>

      </div>
    </div>

    <div v-if="formType === 1">
      <div class="w-full shadow-2xl">
        <div class="card-body pt-0">
          <div class="mt-5" v-if="infoLoading">
            <content-placeholders :rounded="true" :centered="true">
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
            </content-placeholders>
          </div>
          <div v-else>

            <div class="flex flex-col space-y-2" v-if="info">

              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                <div class="form-control mt-5">
                  <label class="label">
                    <span class="label-text font-bold">App Name</span>
                  </label>
                  <input
                      type="text"
                      placeholder="Full Name"
                      class="input input-accent"
                      v-model="info.name"
                  />
                  <small class="text-red-600" v-if="errors.name">{{
                      errors.name[0]
                    }}</small>
                </div>

                <div class="form-control mt-2 md:mt-5">
                  <label class="label">
                    <span class="label-text font-bold">Logo</span>
                  </label>
                  <div class="relative">
                    <span class="logo-edit">
                      <i @click="logoModal = true" class="bx bx-edit clickable"></i>
                    </span>
                    <figure class="flex justify-center">
                      <img
                          :src="url + info.logo"
                          class="w-24 h-24"
                      />
                    </figure>
                  </div>
                  <small class="text-red-600" v-if="errors.logo">{{
                      errors.logo[0]
                    }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">Reward Type</span>
                  </label>
                  <select class="select select-bordered select-accent" v-model="info.rr_type">
                    <option value="" disabled="disabled" selected>
                      Select Type
                    </option>
                    <option :value="1">Once</option>
                    <option :value="2">Lifetime</option>
                  </select>
                  <small class="text-red-600" v-if="errors.rr_type">{{ errors.rr_type[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">Reward Amount<span
                        class="text-xs font-thin"> (Points) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.rr_amount"/>
                  <small class="text-red-600" v-if="errors.rr_amount">{{ errors.rr_amount[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">FFMpeg</span>
                  </label>
                  <select class="select select-bordered select-accent" v-model="info.ffmpeg">
                    <option value="" disabled="disabled" selected>
                      Select Type
                    </option>
                    <option :value="0">Disable</option>
                    <option :value="1">Screenshot Only</option>
                    <option :value="2">Screenshot + Convert</option>
                  </select>
                  <small class="text-red-600" v-if="errors.ffmpeg">{{ errors.ffmpeg[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">New User Reward<span class="text-xs font-thin"> (Points) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent"
                         v-model="info.new_register"/>
                  <small class="text-red-600" v-if="errors.new_register">{{ errors.new_register[0] }}</small>
                </div>

                <div class="form-control mt-5">
                  <div class="flex flex-row justify-between">
                    <div class="label">
                      <span class="label-text font-bold">Need Verification :</span>
                    </div>
                    <div class="flex mr-2 items-center">
                      <input type="checkbox" checked="checked" class="toggle toggle-accent"
                             v-model="info.need_verification"/>
                    </div>
                  </div>
                  <small class="text-red-600" v-if="errors.need_verification">{{ errors.need_verification[0] }}</small>
                </div>

                <div class="form-control mt-5">
                  <div class="flex flex-row justify-between">
                    <div class="label">
                      <span class="label-text font-bold">Manual Payment :</span>
                    </div>
                    <div class="flex mr-2 items-center">
                      <input type="checkbox" checked="checked" class="toggle toggle-accent"
                             v-model="info.manual_payment"/>
                    </div>
                  </div>
                  <small class="text-red-600" v-if="errors.manual_payment">{{ errors.manual_payment[0] }}</small>
                </div>


              </div>

              <div class="divider pb-5 pt-8">Leader Board</div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

                <div class="form-control mt-5">
                  <div class="flex flex-row justify-between">
                    <div class="label">
                      <span class="label-text font-bold">Leaderboard <span class="text-xs font-thin"> (On/Off) </span> :</span>
                    </div>
                    <div class="flex mr-2 items-center">
                      <input type="checkbox" checked="checked" class="toggle toggle-accent" v-model="info.leaderboard"/>
                    </div>
                  </div>
                  <small class="text-red-600" v-if="errors.leaderboard">{{ errors.leaderboard[0] }}</small>
                </div>

                <div>

                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">1st Prize<span class="text-xs font-thin"> (Points) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.prize_1st"/>
                  <small class="text-red-600" v-if="errors.prize_1st">{{ errors.prize_1st[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">2nd Prize<span class="text-xs font-thin"> (Points) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.prize_2nd"/>
                  <small class="text-red-600" v-if="errors.prize_2nd">{{ errors.prize_2nd[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">3rd Prize<span class="text-xs font-thin"> (Points) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.prize_3rd"/>
                  <small class="text-red-600" v-if="errors.prize_3rd">{{ errors.prize_3rd[0] }}</small>
                </div>


              </div>

              <div class="divider pb-2 pt-8">Advertisement</div>

              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

                <div class="form-control mt-5">
                  <label class="label">
                    <span class="label-text font-bold">Ads Type</span>
                  </label>
                  <select class="select select-bordered select-accent" v-model="info.ads_type">
                    <option value="" disabled="disabled" selected>
                      Select Type
                    </option>
                    <option :value="0">PPV + PPD</option>
                    <option :value="1">Pay Per View</option>
                    <option :value="2">Pay Per Day</option>
                  </select>
                  <small class="text-red-600" v-if="errors.ads_type">{{ errors.ads_type[0] }}</small>
                </div>

                <div class="form-control mt-2 md:mt-5">
                  <label class="label">
                    <span class="label-text font-bold">Feed Items<span class="text-xs font-thin"> (Ad after every X post) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.feed_item"/>
                  <small class="text-red-600" v-if="errors.feed_item">{{ errors.feed_item[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">PPV Price<span class="text-xs font-thin"> (Cost Per 1000) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.ppv_price"/>
                  <small class="text-red-600" v-if="errors.ppv_price">{{ errors.ppv_price[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">PPD Price<span
                        class="text-xs font-thin"> (Cost Per 24 Hours) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.ppd_price"/>
                  <small class="text-red-600" v-if="errors.ppd_price">{{ errors.ppd_price[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">PPV Min<span class="text-xs font-thin"> (Minimum Amount) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.ppv_min"/>
                  <small class="text-red-600" v-if="errors.ppv_min">{{ errors.ppv_min[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">PPV Max<span class="text-xs font-thin"> (Maximum Amount) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.ppv_max"/>
                  <small class="text-red-600" v-if="errors.ppv_max">{{ errors.ppv_max[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">PPD Min<span
                        class="text-xs font-thin"> (Minimum Days) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.ppd_min"/>
                  <small class="text-red-600" v-if="errors.ppd_min">{{ errors.ppd_min[0] }}</small>
                </div>

                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">PPD Max<span
                        class="text-xs font-thin"> (Maximum Days) </span></span>
                  </label>
                  <input type="number" placeholder="Input Amount" class="input input-accent" v-model="info.ppd_max"/>
                  <small class="text-red-600" v-if="errors.ppd_max">{{ errors.ppd_max[0] }}</small>
                </div>


              </div>

            </div>


            <div class="flex justify-center">
              <div class="form-control mt-6 item md:w-1/2">
                <button @click="updateInfo" class="btn btn-primary" :disabled="isUpdating">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="isUpdating"/>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 2">
      <div class="pt-4 w-full shadow-2xl">
        <div class="card-body pt-0">

          <div class="flex flex-col space-y-2" v-if="points">

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

              <div class="form-control mt-5">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Earnings <span
                        class="text-xs font-thin"> (On/Off) </span> :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent" v-model="points.earnings"/>
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.earnings">{{ errors.earnings[0] }}</small>
              </div>

              <div class="form-control mt-2 md:mt-5">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Auto Approve <span class="text-xs font-thin"> (Post approve without review) </span> :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent"
                           v-model="points.auto_approve"/>
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.auto_approve">{{ errors.auto_approve[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Image Upload <span class="text-xs font-thin"> (On/Off) </span> :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent"
                           v-model="points.upload_image"/>
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.upload_image">{{ errors.upload_image[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Video Upload <span class="text-xs font-thin"> (On/Off) </span> :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent"
                           v-model="points.upload_video"/>
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.upload_video">{{ errors.upload_video[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Youtube Upload <span class="text-xs font-thin"> (On/Off) </span> :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent"
                           v-model="points.upload_youtube"/>
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.upload_youtube">{{ errors.upload_youtube[0] }}</small>
              </div>
              <div>

              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Max Size <span
                      class="text-xs font-thin"> (Video Upload Size in Mb) </span> :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.max_mb"/>
                <small class="text-red-600" v-if="errors.max_mb">{{ errors.max_mb[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Daily Limit <span
                      class="text-xs font-thin"> (User post limit) </span> : </span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.daily_limit"/>
                <small class="text-red-600" v-if="errors.daily_limit">{{ errors.daily_limit[0] }}</small>
              </div>

            </div>

            <div class="divider pb-2 pt-8">Image Points</div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">Upload Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.add_image"/>
                <small class="text-red-600" v-if="errors.add_image">{{ errors.add_image[0] }}</small>
              </div>

              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">View Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.view_image"/>
                <small class="text-red-600" v-if="errors.view_image">{{ errors.view_image[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Like Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.like_image"/>
                <small class="text-red-600" v-if="errors.like_image">{{ errors.like_image[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Comment Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.comment_image"/>
                <small class="text-red-600" v-if="errors.comment_image">{{ errors.comment_image[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author View Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_view_image"/>
                <small class="text-red-600" v-if="errors.author_view_image">{{ errors.author_view_image[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author Like Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_like_image"/>
                <small class="text-red-600" v-if="errors.author_like_image">{{ errors.author_like_image[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author Comment Image :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_comment_image"/>
                <small class="text-red-600" v-if="errors.author_comment_image">{{
                    errors.author_comment_image[0]
                  }}</small>
              </div>

            </div>


            <div class="divider pb-2 pt-8">Video Points</div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">Upload Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.add_video"/>
                <small class="text-red-600" v-if="errors.add_video">{{ errors.add_video[0] }}</small>
              </div>

              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">View Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.view_video"/>
                <small class="text-red-600" v-if="errors.view_video">{{ errors.view_video[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Like Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent" v-model="points.like_video"/>
                <small class="text-red-600" v-if="errors.like_video">{{ errors.like_video[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Comment Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.comment_video"/>
                <small class="text-red-600" v-if="errors.comment_video">{{ errors.comment_video[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author View Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_view_video"/>
                <small class="text-red-600" v-if="errors.author_view_video">{{ errors.author_view_video[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author Like Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_like_video"/>
                <small class="text-red-600" v-if="errors.author_like_video">{{ errors.author_like_video[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author Comment Video :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_comment_video"/>
                <small class="text-red-600" v-if="errors.author_comment_video">{{
                    errors.author_comment_video[0]
                  }}</small>
              </div>

            </div>

            <div class="divider pb-2 pt-8">Youtube Points</div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">Upload Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.add_youtube"/>
                <small class="text-red-600" v-if="errors.add_youtube">{{ errors.add_youtube[0] }}</small>
              </div>

              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">View Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.view_youtube"/>
                <small class="text-red-600" v-if="errors.view_youtube">{{ errors.view_youtube[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Like Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.like_youtube"/>
                <small class="text-red-600" v-if="errors.like_youtube">{{ errors.like_youtube[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Comment Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.comment_youtube"/>
                <small class="text-red-600" v-if="errors.comment_youtube">{{ errors.comment_youtube[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author View Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_view_youtube"/>
                <small class="text-red-600" v-if="errors.author_view_youtube">{{
                    errors.author_view_youtube[0]
                  }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author Like Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_like_youtube"/>
                <small class="text-red-600" v-if="errors.author_like_youtube">{{
                    errors.author_like_youtube[0]
                  }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Author Comment Youtube :</span>
                </label>
                <input type="number" placeholder="Input Amount" class="input input-accent"
                       v-model="points.author_comment_youtube"/>
                <small class="text-red-600" v-if="errors.author_comment_youtube">{{
                    errors.author_comment_youtube[0]
                  }}</small>
              </div>

            </div>


          </div>

          <div class="flex justify-center">
            <div class="form-control mt-8 item md:w-1/2">
              <button
                  @click="updateEarnings"
                  class="btn btn-primary"
                  :disabled="isEarnUpdating"
              >
                <svg-loading
                    height="16px"
                    width="16px"
                    class="mr-1"
                    v-if="isEarnUpdating"
                />
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 3">
      <div class="pt-4 w-full shadow-2xl">
        <div class="card-body pt-0">

          <div class="flex flex-col space-y-2">

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">Mail Host: </span>
                </label>
                <input type="text" placeholder="mail.example.com" class="input input-accent" v-model="mail.mail_host"/>
                <small class="text-red-600" v-if="errors.mail_host">{{ errors.mail_host[0] }}</small>
              </div>

              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">Mail Port: </span>
                </label>
                <input type="number" placeholder="25" class="input input-accent" v-model="mail.mail_port"/>
                <small class="text-red-600" v-if="errors.mail_port">{{ errors.mail_port[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Mail Username: </span>
                </label>
                <input type="text" placeholder="no-reply@example.com" class="input input-accent"
                       v-model="mail.mail_username"/>
                <small class="text-red-600" v-if="errors.mail_username">{{ errors.mail_username[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Mail Password: </span>
                </label>
                <input type="text" placeholder="********" class="input input-accent" v-model="mail.mail_password"/>
                <small class="text-red-600" v-if="errors.mail_password">{{ errors.mail_password[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Mail Encryption: </span>
                </label>
                <input type="text" placeholder="tls" class="input input-accent" v-model="mail.mail_encryption"/>
                <small class="text-red-600" v-if="errors.mail_encryption">{{ errors.mail_encryption[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Mail From Address: </span>
                </label>
                <input type="text" placeholder="no-reply@example.com" class="input input-accent"
                       v-model="mail.mail_from_address"/>
                <small class="text-red-600" v-if="errors.mail_from_address">{{ errors.mail_from_address[0] }}</small>
              </div>

            </div>

          </div>
          <div class="flex justify-center">
            <div class="form-control mt-8 item md:w-1/2">
              <button
                  @click="updateMailConfig"
                  class="btn btn-primary"
                  :disabled="isConfigUpdating"
              >
                <svg-loading
                    height="16px"
                    width="16px"
                    class="mr-1"
                    v-if="isConfigUpdating"
                />
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 4">
      <div class="pt-4 w-full shadow-2xl">
        <div class="card-body pt-0">

          <div class="flex flex-col space-y-2">

            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">

              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">Stripe Key: </span>
                </label>
                <input type="text" placeholder="your stripe key" class="input input-accent"
                       v-model="payment.stripe_key"/>
                <small class="text-red-600" v-if="errors.stripe_key">{{ errors.stripe_key[0] }}</small>
              </div>

              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">Stripe Secret: </span>
                </label>
                <input type="text" placeholder="your stripe secret" class="input input-accent"
                       v-model="payment.stripe_secret"/>
                <small class="text-red-600" v-if="errors.stripe_secret">{{ errors.stripe_secret[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Stripe Currency: </span>
                </label>
                <input type="text" placeholder="USD" class="input input-accent" v-model="payment.stripe_currency"/>
                <small class="text-red-600" v-if="errors.stripe_currency">{{ errors.stripe_currency[0] }}</small>
              </div>

              <div></div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Mollie Key: </span>
                </label>
                <input type="text" placeholder="Mollie Key" class="input input-accent" v-model="payment.mollie_key"/>
                <small class="text-red-600" v-if="errors.mollie_key">{{ errors.mollie_key[0] }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Mollie Currency: </span>
                </label>
                <input type="text" placeholder="USD" class="input input-accent" v-model="payment.mollie_currency"/>
                <small class="text-red-600" v-if="errors.mollie_currency">{{ errors.mollie_currency[0] }}</small>
              </div>

            </div>

          </div>
          <div class="flex justify-center">
            <div class="form-control mt-8 item md:w-1/2">
              <button
                  @click="updatePaymentConfig"
                  class="btn btn-primary"
                  :disabled="isConfigUpdating"
              >
                <svg-loading
                    height="16px"
                    width="16px"
                    class="mr-1"
                    v-if="isConfigUpdating"
                />
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
        :if-close="true"
        :showing="logoModal"
        @update:modal="logoModal = $event"
    >
      <div
          v-if="logoModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Update Logo</div>

        <form @submit.prevent="updateLogo">


          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Logo : </span>
            </label>
            <input
                accept=".jpg,.png,.jpeg,.heic"
                class="file"
                type="file"
                @change="logoChange"
                ref="logoInput"
            />
            <small v-if="errors.logo" class="text-red-600">{{
                errors.logo[0]
              }}</small>
          </div>
          <div v-if="logo" class="form-control mt-4">
            <div class="flex flex-wrap justify-center">
              <div class="w-8/12 sm:w-6/12 px-4">
                <img
                    :src="logo"
                    alt="Logo"
                    class="rounded max-w-full h-auto align-middle border-none"
                />
              </div>
            </div>
          </div>

          <div class="form-control mt-8 mb-4">
            <button
                :disabled="isLogoUpdating"
                class="btn btn-primary"
                type="submit"
            >
              <svg-loading
                  v-if="isLogoUpdating"
                  class="mr-1"
                  height="16px"
                  width="16px"
              />
              Update Logo
            </button>
          </div>
        </form>
      </div>
    </modal>

  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.$Progress.finish()
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      formType: 1,
      info: {},
      points: {},
      mail: {},
      payment: {},
      errors: {},
      loading: false,
      isUpdating: false,
      isEarnUpdating: false,
      isConfigUpdating: false,
      infoLoading: false,
      logo: null,
      logoModal: false,
      isLogoUpdating: false,
    }
  },
  methods: {
    async getInfo() {
      this.infoLoading = true;
      try {
        let response = await axios.get("/admin/settings");
        this.info = response.data.info;
        this.points = response.data.points;
        this.mail = response.data.mail;
        this.payment = response.data.payment;
        this.infoLoading = false;
      } catch (e) {
        this.infoLoading = false;
      }
    },

    async updateInfo() {
      this.isUpdating = true;
      this.errors = {};
      try {
        let response = await this.$axios.post('/admin/settings-info', this.info);
        this.isUpdating = false;
        this.$noti('success', response.data.message);
      } catch (e) {
        this.isUpdating = false;
        this.errors = e.response.data.errors
        this.$noti('error', e.response.data.message)
      }
    },
    async updateEarnings() {
      this.isEarnUpdating = true;
      this.errors = {};
      try {
        let response = await this.$axios.post('/admin/settings-earnings', this.points)
        this.isEarnUpdating = false;
        this.$noti('success', response.data.message)
      } catch (e) {
        this.isEarnUpdating = false;
        this.errors = e.response.data.errors
        this.$noti('error', e.response.data.message)
      }
    },

    async updateMailConfig() {
      this.isConfigUpdating = true;
      this.errors = {};
      try {
        let response = await this.$axios.post('/admin/mail-config', this.mail)
        this.isConfigUpdating = false;
        this.$noti('success', response.data.message)
      } catch (e) {
        this.isConfigUpdating = false;
        this.errors = e.response.data.errors
        this.$noti('error', e.response.data.message)
      }
    },

    async updatePaymentConfig() {
      this.isConfigUpdating = true;
      this.errors = {};
      try {
        let response = await this.$axios.post('/admin/payment-config', this.payment)
        this.isConfigUpdating = false;
        this.$noti('success', response.data.message)
      } catch (e) {
        this.isConfigUpdating = false;
        this.errors = e.response.data.errors
        this.$noti('error', e.response.data.message)
      }
    },

    async updateLogo() {
      this.isLogoUpdating = true;
      try {
        let response = await axios.post('/admin/logo', {image: this.logo})
        this.isLogoUpdating = false;
        this.$noti('success', response.data.message);
        this.logo = null;
        this.$refs.logoInput.value = null;
      } catch (e) {
        this.isLogoUpdating = false;
        this.$noti('error', e.response.data.message)
      }
    },

    logoChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.logo = event.target.result;
      };
      reader.readAsDataURL(file);
    },

  },
}
</script>

<style scoped></style>