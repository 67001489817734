<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Users</div>
            <router-link class="btn btn-accent btn-xs" to="/create-user">
              Add User
            </router-link>
            <div class="btn btn-accent btn-xs" @click="getUsers">Refresh</div>
          </div>
          <div class="divider mt-0">Filter</div>

          <div class="flex flex-col md:flex-row justify-between space-y-2 px-2">
            <div class="flex flex-col lg:flex-row justify-between space-y-3 space-x-5 px-2">
              <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
                <div class="font-bold">Type :</div>
                <div>
                  <select v-model="filter.type" class="select select-accent select-sm w-full max-w-xs"
                    @change="getUsers">
                    <option selected value="10">All</option>
                    <option value="1">Active</option>
                    <option value="2">Blocked</option>
                    <option value="3">Not Verified</option>
                  </select>
                </div>
              </div>

              <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
                <div class="font-bold">SortBy :</div>
                <div>
                  <select v-model="filter.sortBy" class="select select-accent select-sm w-full max-w-xs"
                    @change="getUsers">
                    <option selected value="desc">Latest</option>
                    <option value="asc">Oldest</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flex flex-col lg:flex-row justify-between space-x-5 space-y-3 px-2">
              <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
                <div class="font-bold">Per Page :</div>
                <div>
                  <input v-model="filter.per_page" class="input input-accent input-sm md:w-1/2 w-full" min="10"
                    placeholder="Show per page" type="number" @change="getUsers" />
                </div>
              </div>

              <div class="flex flex-col md:flex-row items-center space-y-3 md:space-x-2">
                <div>
                  <input v-model="filter.search" class="input input-accent input-sm" placeholder="Search" type="text" />
                </div>
                <div class="btn btn-secondary btn-xs" @click="getUsers">
                  Search
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading />
            <content-placeholders-heading />
            <content-placeholders-heading />
            <content-placeholders-heading />
            <content-placeholders-heading />
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="userData.data">
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3">
              <div v-for="(user, index) in userData.data" :key="index">
                <div class="card p-2 shadow-lg compact bg-base-100 space-y-1 mx-1 mt-3 mb-2">
                  <div class="flex justify-center mt-1 mb-2">
                    <div class="indicator">
                      <div v-if="user.verified" class="pt-2 mr-3 indicator-item">
                        <verification-svg height="25px" width="25px" />
                      </div>
                      <div class="avatar">
                        <div v-if="user.image" class="w-24 h-24 p-px mask mask-squircle bg-base-content bg-opacity-10">
                          <img :src="url + '/' + user.image" :alt="user.name" class="mask mask-squircle" height="94"
                            width="94" />
                        </div>
                        <div v-else class="w-24 h-24 p-px mask mask-squircle bg-base-content bg-opacity-10">
                          <img :src="url + '/user/no-image.png'" alt="RewardStatus" class="mask mask-squircle"
                            height="94" width="94" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Name:</div>
                    <div class="text-right">{{ user.name }}</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Username:</div>
                    <div class="text-right">{{ user.username }}</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Balance:</div>
                    <div class="flex flex-row space-x-1 items-center">
                      <coin-svg height="17px" width="17px" />
                      <div class="text-base font-semibold">
                        {{ user.balance }}
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Email:</div>
                    <div class="text-right">{{ user.email }}</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Package:</div>
                    <div class="text-right">{{ user.plan }}</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Phone:</div>
                    <div v-if="user.phone" class="text-right">
                      {{ user.phone }}
                    </div>
                    <div v-else class="text-right">N/A</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Ip:</div>
                    <div v-if="user.register_ip" class="text-right">
                      {{ user.register_ip }}
                    </div>
                    <div v-else class="text-right">N/A</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Last Ip:</div>
                    <div v-if="user.last_ip" class="text-right">
                      {{ user.last_ip }}
                    </div>
                    <div v-else class="text-right">N/A</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Country:</div>
                    <div v-if="user.country && user.country.name" class="text-right">
                      {{ user.country.name }}
                    </div>
                    <div v-else class="text-right">N/A</div>
                  </div>

                  <div class="flex flex-row justify-between space-x-5 mx-1">
                    <div class="font-bold">Joined:</div>
                    <div class="text-right">
                      {{ user.created_at | dateFormat }}
                    </div>
                  </div>

                  <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">
                    <router-link :to="{
                      name: 'user-details',
                      params: { username: user.username },
                    }" class="btn btn-primary btn-sm">
                      <i class="bx bx-info-circle"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="total == 0">
            <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2">
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination :data="userData" :limit="3" class="menu menu-horizontal bg-base-100 rounded-box p-0"
            @pagination-change-page="getUsers"></pagination>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    if (!this.userData.data.length) {
      this.getUsers();
    }
    this.$Progress.finish();
  },

  data() {
    return {
      url: this.$config.FILE_URL,
      total: null,
      filter: {
        type: "10",
        per_page: 12,
        sortBy: "desc",
        search: null,
      },
      isLoading: false,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      userData: "users",
    }),
  },
  methods: {
    ...mapActions({
      getUserData: "getUsers",
    }),
    getUsers(page) {
      this.isLoading = true;
      let type = this.filter.type;
      if (type !== "") {
        if (type == 10) {
          type = "";
        }
      }
      let sortBy = this.filter.sortBy;
      let cp = page ? page : this.userData.current_page;
      let urlData = {
        params: {
          type: type,
          sortBy: sortBy,
          per_page: this.filter.per_page,
          search: this.filter.search,
          page: cp,
        },
      };
      this.getUserData(urlData)
        .then(() => {
          this.total = this.userData.total;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
