<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Statuses</div>
            <div class="btn btn-accent btn-xs" @click="getStatuses">Refresh</div>
          </div>
          <div class="divider mt-0">Filter</div>

          <div class="flex flex-col md:flex-row justify-around space-y-2 px-2">

            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">Type :</div>
              <div>
                <select class="select select-accent select-sm w-full max-w-xs" v-model="filter.type"
                        @change="getStatuses">
                  <option value="10" selected>All</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  <option value="youtube">YouTube</option>
                </select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">SortBy :</div>
              <div>
                <select class="select select-accent select-sm w-full max-w-xs" v-model="filter.sortBy"
                        @change="getStatuses">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">Per Page :</div>
              <div>
                <input v-model="filter.per_page" class="input input-accent input-sm" placeholder="Show per page"
                       type="number" min="10" @change="getStatuses"/>
              </div>
            </div>

          </div>

          <div class="flex flex-col md:flex-row justify-around space-y-2 px-2 mt-3">


            <div class="flex flex-row justify-center space-x-3">
              <div class="font-bold">Multiple :</div>
              <div class="flex flex-row space-x-2">
                <div class="btn btn-sm" :class="statusData.total > 0 ? 'btn-primary' : 'btn-disabled' "
                     @click="selectAction">{{ selectBtn }}
                </div>
                <div class="btn btn-sm" :class="status_ids.length ? 'btn-success' : 'btn-disabled' "
                     @click="confirmButtonClick(null, 3)">
                  <i class="bx bx-check"></i>
                </div>
                <div class="btn btn-sm" :class="status_ids.length ? 'btn-error' : 'btn-disabled' "
                     @click="confirmButtonClick(null, 4)">
                  <i class="bx bx-x"></i>
                </div>
              </div>
            </div>

            <div class="flex flex-col md:flex-row items-center space-y-3 md:space-x-2">
              <div>
                <input v-model="filter.search" class="input input-accent input-sm" placeholder="Search" type="text"/>
              </div>
              <div class="btn btn-secondary btn-xs" @click="getStatuses">Search</div>
            </div>

          </div>

        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="statusData.data">
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3">
              <div v-for="(status, index) in statusData.data" :key="index">
                <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2">
                  <div class="flex-row items-center space-x-2.5 card px-1 pb-1">
                    <div class="flex-1">
                      <div class="flex-row items-center space-x-3 card mt-1 mb-2">
                        <div class="avatar">
                          <div class="rounded-full w-10 h-10 shadow clickable" @click="viewUser(status.user.username)">
                            <img v-if="status.user.image" :src="url + '/' + status.user.image"/>
                            <img v-else :src="url + '/user/no-image.png'"/>
                          </div>
                        </div>
                        <div class="flex flex-row space-x-1.5 items-center" @click="viewUser(status.user.username)">
                          <div class="font-bold text-lg ml-1 clickable">
                            {{ status.user.name }}
                          </div>
                          <div v-if="status.user.verified">
                            <verification-svg height="17px" width="17px"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex-0">
                      <div class="flex-row items-center space-x-2.5 card mt-1 mb-2">
                        <h2 class="text-sm">{{ status.created_at | fromNow }}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col justify-around space-y-2">
                    <div class="avatar self-center mb-2">
                      <div class="w-80 rounded">
                        <div class="relative">
                          <div class="status-type">
                            <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="status.id"
                                   v-model="status_ids">
                          </div>
                          <div>
                            <figure>
                              <img v-if="status.thumbnail" :src="url + '/' + status.thumbnail"/>
                              <img v-else :src="url + '/user/no-image.png'"/>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-5 mx-1">
                      <div class="font-bold">Title:</div>
                      <div class="text-right">
                        <h1 v-if="status.title && status.title.length < 80">
                          {{ status.title }}
                        </h1>
                        <h1 v-if="status.title && status.title.length > 80">
                          {{ status.title.substring(0, 80) + "..." }}
                        </h1>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-3 mx-1">
                      <div class="font-bold">Type :</div>
                      <div>
                        <div
                            v-if="status.type == 'image' "
                            class="badge badge-warning"
                        >
                          Image
                        </div>
                        <div
                            v-if="status.type == 'video' "
                            class="badge badge-success"
                        >
                          Video
                        </div>
                        <div
                            v-if="status.type == 'youtube' "
                            class="badge badge-error"
                        >
                          YouTube
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-3 mx-1"
                         v-if="status.categories && status.categories.length">
                      <div class="font-bold">Categories :</div>
                      <div class="flex flex-wrap justify-end items-center space-x-2 space-y-1">
                        <div class="badge badge-outline" v-for="(category, index) in status.categories" :key="index">
                          {{ category.name }}
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">

                    <router-link :to="{name: 'review-details', params:{code: status.code}}"
                                 class="btn btn-primary btn-sm">
                      <i class="bx bx-info-circle"></i>
                    </router-link>

                    <div class="btn btn-sm" :class="!status_ids.length ? 'btn-success' : 'btn-disabled'"
                         @click="confirmButtonClick(status, 1)">
                      <i class="bx bx-check"></i>
                    </div>
                    <div class="btn btn-sm" :class="!status_ids.length ? 'btn-error' : 'btn-disabled'"
                         @click="confirmButtonClick(status, 2)">
                      <i class="bx bx-x"></i>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="total == 0">
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="statusData" :limit="3"
                      @pagination-change-page="getStatuses"></pagination>
        </div>
      </div>
    </div>

    <modal
        :if-close="true"
        :showing="confirmModal"
        @update:modal="confirmModal = $event"
    >
      <div
          v-if="confirmModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Are You Sure ?</div>
        <div class="text-xl mt-5" v-if="modalInfo.type === 1">
          Approve : {{ modalInfo.title }} from {{ modalInfo.name }} !<span> </span>
        </div>
        <div class="text-xl mt-5" v-if="modalInfo.type === 2">
          Delete : {{ modalInfo.title }} from {{ modalInfo.name }} !<span> </span>
        </div>
        <div class="text-xl mt-5" v-if="modalInfo.type === 3">
          Approve : Selected {{ status_ids.length }} statuses !<span> </span>
        </div>
        <div class="text-xl mt-5" v-if="modalInfo.type === 4">
          Delete : Selected {{ status_ids.length }} statuses !<span> </span>
        </div>
        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button :disabled="isUpdating" class="btn btn-outline" @click="confirmModal = false">
              Cancel
            </button>
            <button :disabled="isUpdating" class="btn btn-success" v-if="modalInfo.type === 1"
                    @click="approveStatus(modalInfo.id, 1)">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Approve
            </button>
            <button :disabled="isUpdating" class="btn btn-error" v-if="modalInfo.type === 2"
                    @click="deleteStatus(modalInfo.id, 1)">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Delete
            </button>
            <button :disabled="isUpdating" class="btn btn-success" v-if="modalInfo.type === 3"
                    @click="approveStatus(status_ids, 2)">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Approve All
            </button>
            <button :disabled="isUpdating" class="btn btn-error" v-if="modalInfo.type === 4"
                    @click="deleteStatus(status_ids, 2)">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Delete All
            </button>
          </div>
        </div>
      </div>
    </modal>

  </main>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    if (!this.statusData.data.length) {
      this.getStatuses();
    }
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters({
      statusData: 'reviewStatuses',
    }),
    selectBtn() {
      if (this.statusData.total > 0) {
        let list = this.statusData.data;
        let ids = this.status_ids;
        if (list.length === ids.length) {
          return "Deselect All";
        } else {
          return "Select All";
        }
      } else {
        return "Select All";
      }
    }
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      total: null,
      modalInfo: {},
      filter: {
        type: '10',
        per_page: 12,
        sortBy: 'desc',
        search: null,
      },
      status_ids: [],
      isLoading: false,
      isUpdating: false,
      errors: {},
      confirmModal: false,
    };
  },

  methods: {
    ...mapActions({
      getStatusData: 'getReviewStatuses'
    }),
    getStatuses(page) {
      this.isLoading = true;
      let type = this.filter.type;
      if (type !== '') {
        if (type == 10) {
          type = ''
        }
      }
      let sortBy = this.filter.sortBy;
      let cp = page ? page : this.statusData.current_page;
      let urlData = {
        params: {
          type: type,
          sortBy: sortBy,
          per_page: this.filter.per_page,
          search: this.filter.search,
          page: cp
        }
      };
      this.getStatusData(urlData).then(() => {
        this.total = this.statusData.total;
        this.isLoading = false;
      })
          .catch(() => {
            this.isLoading = false;
          });
    },
    selectAction() {
      let list = this.statusData.data;
      let ids = this.status_ids;
      if (list.length === ids.length) {
        this.status_ids = [];
      } else {
        this.status_ids = list.map(a => a.id);
      }
    },

    approveStatus(id, type) {
      this.isUpdating = true;
      axios.post('/admin/approve-status', {id: id, type: type})
          .then(() => {
            this.isUpdating = false;
            this.getStatuses();
            this.$noti('success', 'Approved Successfully');
            this.confirmModal = false;
          }).catch(() => {
        this.isUpdating = false;
        this.$noti('error', 'Something went wrong')
      })
    },
    deleteStatus(id, type) {
      this.isUpdating = true;
      axios.post('/admin/delete-status', {id: id, type: type})
          .then(() => {
            this.isUpdating = false;
            this.getStatuses();
            this.$noti('warning', 'Deleted Successfully');
            this.confirmModal = false;
          }).catch((error) => {
        this.isUpdating = false;
        if (error.response.status === 406) {
          this.$noti("warning", error.response.data.message);
        } else {
          this.errors = error.response.data.errors;
          this.$noti("error", "Something Went Wrong !");
        }
      })

    },
    confirmButtonClick(data, type) {
      try {
        if (type === 1 || type === 2) {
          this.modalInfo = {
            id: data.id,
            title: data.title,
            name: data.user.name,
            type: type
          }
        } else {
          this.modalInfo = {
            type: type
          }
        }
      } finally {
        this.confirmModal = true;
      }
    },

    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: {username: username},
      });
    },

  },
};
</script>

<style scoped></style>