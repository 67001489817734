<template>
  <main class="justify-center mx-auto w-full shadow-2xl p-2 bg-base-100 mx-1">
    <div class="mx-2 mt-3">
      <div class="tabs tabs-boxed">
        <div :class="formType === 1 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 1">
          Edit Info
        </div>
        <div :class="formType === 2 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 2">
          Update Password
        </div>
        <div :class="formType === 3 ? 'tab-active' : ''" class="flex-grow tab" @click="formType = 3">
          Update Image
        </div>
      </div>
    </div>

    <div v-if="formType === 1">
      <div class="w-full shadow-2xl">
        <div class="card-body pt-0">
          <div class="mt-5" v-if="infoLoading">
            <content-placeholders :rounded="true" :centered="true">
              <content-placeholders-heading />
              <content-placeholders-heading />
              <content-placeholders-heading />
              <content-placeholders-heading />
              <content-placeholders-heading />
              <content-placeholders-heading />
              <content-placeholders-heading />
            </content-placeholders>
          </div>
          <div v-else>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">Full Name</span>
                </label>
                <input type="text" placeholder="Full Name" class="input input-accent" v-model="form.name" />
                <small class="text-red-600" v-if="errors.name">{{
                    errors.name[0]
                }}</small>
              </div>
              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">Bio</span>
                </label>
                <textarea class="textarea h-24 textarea-bordered textarea-accent" placeholder="Add a Bio"
                  v-model="form.bio"></textarea>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Username</span>
                </label>
                <input type="text" placeholder="username" class="input input-accent" v-model="form.username" />
                <small class="text-red-600" v-if="errors.username">{{
                    errors.username[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Email</span>
                </label>
                <input type="email" placeholder="Email Address" class="input input-accent" v-model="form.email" />
                <small class="text-red-600" v-if="errors.email">{{
                    errors.email[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Country</span>
                </label>
                <select class="select select-bordered select-accent" v-model="form.country_code">
                  <option value="" disabled="disabled" selected="selected">
                    Choose Country
                  </option>
                  <option :value="country.code" v-for="country in countries" :key="country.id">
                    {{ country.name }}
                  </option>
                </select>
                <small class="text-red-600" v-if="errors.country_code">{{
                    errors.country_code[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Phone Number</span>
                </label>
                <input type="text" placeholder="Mobile Number" class="input input-accent" v-model="form.phone" />
                <small class="text-red-600" v-if="errors.phone">{{
                    errors.phone[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Gender</span>
                </label>
                <select class="select select-bordered select-accent" v-model="form.gender">
                  <option value="" disabled="disabled" selected>
                    Select Gender
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                  <option value="3">Others</option>
                </select>
                <small class="text-red-600" v-if="errors.gender">{{
                    errors.gender[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Package</span>
                </label>
                <select class="select select-bordered select-accent" v-model="form.plan">
                  <option value="" disabled="disabled" selected>
                    Select Package
                  </option>
                  <option value="FE">FE</option>
                  <option value="DFY">DFY</option>
                  <option value="UNLIMITED">UNLIMITED</option>
                  <option value="PROFIT BOOSTER">PROFIT BOOSTER</option>
                </select>
                <small class="text-red-600" v-if="errors.plan">{{
                    errors.plan[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Facebook</span>
                </label>
                <input type="text" placeholder="Facebook Username" class="input input-accent" v-model="form.fb" />
                <small class="text-red-600" v-if="errors.fb">{{
                    errors.fb[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Instagram</span>
                </label>
                <input type="text" placeholder="Instagram Username" class="input input-accent" v-model="form.ig" />
                <small class="text-red-600" v-if="errors.ig">{{
                    errors.ig[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">YouTube</span>
                </label>
                <input type="text" placeholder="YouTube Username" class="input input-accent" v-model="form.yt" />
                <small class="text-red-600" v-if="errors.yt">{{
                    errors.yt[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">TikTok</span>
                </label>
                <input type="text" placeholder="TikTok Username" class="input input-accent" v-model="form.tt" />
                <small class="text-red-600" v-if="errors.tt">{{
                    errors.tt[0]
                }}</small>
              </div>
              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Twitter</span>
                </label>
                <input type="text" placeholder="Twitter Username" class="input input-accent" v-model="form.tw" />
                <small class="text-red-600" v-if="errors.tw">{{
                    errors.tw[0]
                }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Balance</span>
                </label>
                <input type="number" placeholder="Balance" class="input input-accent" v-model="form.balance" />
                <small class="text-red-600" v-if="errors.balance">{{
                    errors.balance[0]
                }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Fund</span>
                </label>
                <input type="number" placeholder="Balance" class="input input-accent" v-model="form.fund" />
                <small class="text-red-600" v-if="errors.fund">{{
                    errors.fund[0]
                }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Lifetime Earning</span>
                </label>
                <input type="number" placeholder="Lifetime Earning" class="input input-accent"
                  v-model="form.lifetime_earning" />
                <small class="text-red-600" v-if="errors.lifetime_earning">{{
                    errors.lifetime_earning[0]
                }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">LeaderBoard Points</span>
                </label>
                <input type="number" placeholder="Leaderboard Points" class="input input-accent"
                  v-model="form.lb_points" />
                <small class="text-red-600" v-if="errors.lb_points">{{
                    errors.lb_points[0]
                }}</small>
              </div>

              <div class="form-control mt-2">
                <label class="label">
                  <span class="label-text font-bold">Daily Limit Use</span>
                </label>
                <input type="number" placeholder="Daily Limit Use" class="input input-accent"
                  v-model="form.daily_limit" />
                <small class="text-red-600" v-if="errors.daily_limit">{{
                    errors.daily_limit[0]
                }}</small>
              </div>

              <div></div>

              <div class="form-control mt-5">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Verified Badge
                      <span class="text-xs font-thin"> (Auto Approve) </span>
                      :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent" v-model="form.verified" />
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.verified">{{
                    errors.verified[0]
                }}</small>
              </div>

              <div class="form-control mt-5">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Auth Verified :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent"
                      v-model="form.auth_verified" />
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.auth_verified">{{
                    errors.auth_verified[0]
                }}</small>
              </div>

              <div class="form-control mt-5">
                <div class="flex flex-row justify-between">
                  <div class="label">
                    <span class="label-text font-bold">Blocked :</span>
                  </div>
                  <div class="flex mr-2 items-center">
                    <input type="checkbox" checked="checked" class="toggle toggle-accent" v-model="form.banned" />
                  </div>
                </div>
                <small class="text-red-600" v-if="errors.banned">{{
                    errors.banned[0]
                }}</small>
              </div>
            </div>
            <div class="flex justify-center">
              <div class="form-control mt-6 item md:w-1/2">
                <button @click="updateInfo" class="btn btn-primary" :disabled="isUpdating">
                  <svg-loading height="16px" width="16px" class="mr-1" v-if="isUpdating" />
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 2">
      <div class="pt-4 w-full shadow-2xl">
        <div class="card-body pt-0">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">New Password</span>
              </label>
              <input type="password" placeholder="New Password" class="input input-accent" v-model="newPass.password" />
              <small class="text-red-600" v-if="errors.password">{{
                  errors.password[0]
              }}</small>
            </div>
            <div class="form-control mt-2 md:mt-5">
              <label class="label">
                <span class="label-text font-bold">Confirm Password</span>
              </label>
              <input type="password" placeholder="Confirm Password" class="input input-accent"
                v-model="newPass.password_confirmation" />
              <small class="text-red-600" v-if="errors.password_confirmation">{{
                  errors.password_confirmation[0]
              }}</small>
            </div>
          </div>
          <div class="flex justify-center">
            <div class="form-control mt-8 item md:w-1/2">
              <button @click="updatePass" class="btn btn-primary" :disabled="isPassUpdating">
                <svg-loading height="16px" width="16px" class="mr-1" v-if="isPassUpdating" />
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 3">
      <div class="pt-6 w-full shadow-2xl">
        <div class="card-body pt-0">
          <form @submit.prevent="updateImage">
            <div class="form-control mt-5">
              <input type="file" accept=".jpg,.png,.jpeg,.heic" class="file" @change="imageChange" />
              <small class="text-red-600" v-if="errors.image">{{
                  errors.image[0]
              }}</small>
            </div>
            <div class="form-control mt-5" v-if="image">
              <div class="flex flex-wrap justify-center">
                <div class="w-8/12 sm:w-6/12 px-4">
                  <img :src="image" alt="..." class="rounded max-w-full h-auto align-middle border-none" />
                </div>
              </div>
            </div>

            <div class="mt-4 p-6" v-if="uploading">
              <progress class="progress progress-accent" :value="uploadPercentage" max="100"></progress>
            </div>

            <div class="form-control mt-8">
              <button type="submit" class="btn btn-primary" :disabled="uploading">
                <svg-loading height="16px" width="16px" class="mr-1" v-if="uploading" />
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getInfo();
    this.getCountries();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      formType: 1,
      form: {
        name: null,
        username: null,
        email: null,
        phone: null,
        gender: "",
        plan: "",
        country_code: "",
        bio: "",
        fb: "",
        ig: "",
        yt: "",
        tt: "",
        tw: "",
      },
      newPass: {
        password: "",
        password_confirmation: "",
      },
      countries: {},
      image: null,
      uploading: false,
      uploadPercentage: 0,
      errors: {},
      loading: false,
      isUpdating: false,
      isPassUpdating: false,
      infoLoading: false,
    };
  },
  methods: {
    async getInfo() {
      this.infoLoading = true;
      let username = this.$route.params.username;
      try {
        let response = await axios.get("/admin/user-info", {
          params: { username: username },
        });
        this.form = response.data;
        this.infoLoading = false;
      } catch (e) {
        this.infoLoading = false;
      }
    },
    async getCountries() {
      try {
        let response = await axios.get("/admin/country-list");
        this.countries = response.data;
      } catch (e) { }
    },
    async updateInfo() {
      this.isUpdating = true;
      this.errors = {};
      this.$set(this.form, "real_username", this.$route.params.username);
      try {
        let response = await this.$axios.post("/admin/user-info", this.form);
        this.isUpdating = false;
        this.$noti("success", "Info Updated");
        let username = response.data.username;
        if (username != this.$route.params.username) {
          await this.$router.replace({
            name: "user-details",
            params: { username: username },
          });
        }
      } catch (e) {
        this.isUpdating = false;
        this.errors = e.response.data.errors;
        this.$noti("error", "Something Went Wrong");
      }
    },
    async updatePass() {
      this.isPassUpdating = true;
      this.$set(this.newPass, "username", this.$route.params.username);
      this.errors = {};
      try {
        let response = await this.$axios.post("/admin/user-pass", this.newPass);
        this.isPassUpdating = false;
        this.$noti("success", "Pass Updated");
      } catch (e) {
        this.isPassUpdating = false;
        this.errors = e.response.data.errors;
        this.$noti("error", "Something Went Wrong");
      }
    },
    async updateImage() {
      this.uploading = true;
      let username = this.$route.params.username;
      this.errors = {};
      try {
        let response = await axios.post(
          "/admin/user-image",
          { username: username, image: this.image },
          {
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          }
        );
        this.uploading = false;
        this.image = null;
        this.$noti("success", "" + response.data.message + "");
      } catch (e) {
        this.uploading = false;
        this.$noti("error", "Something Went Wrong");
      }
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped>
</style>
