import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index";

Vue.use(VueRouter)

//auth
import login from "../views/auth/login";
import activate from "../views/auth/activate";
// Main
import Dashboard from '../views/Dashboard.vue';
import Users from "../views/users/All";
import CreateUser from "../views/users/Create";
import UserDetails from "../views/users/Details";
import DFYStatus from "../views/dfy/Index.vue";
import CreateDFYStatus from "../views/dfy/Create.vue";
import VideoDFYStatus from "../views/dfy/Video.vue";
import YoutubeDFYStatus from "../views/dfy/Youtube.vue";
import DFYStatusEdit from "../views/dfy/Edit.vue";
import Status from "../views/status/Index";
import StatusDetails from "../views/status/Details";
import StatusEdit from "../views/status/Edit";
import StatusReview from "../views/review/Index";
import ReviewDetails from "../views/review/Details";
import Category from "../views/Category";
import Slides from "../views/Slides";
import Languages from "../views/Languages";
import Currencies from "../views/Currencies";
import Packages from "../views/Packages";
import EditPackages from "../views/package/Edit";
import Pages from "../views/Pages";
import Deposits from "../views/Deposits";
import DepositMethods from "../views/DepositMethods";
import Withdrawals from "../views/Withdrawals";
import WithdrawalMethods from "../views/WithdrawalMethods";
import SupportTickets from "../views/SupportTickets";
import SupportMessages from "../views/SupportMessages";
import Advertisement from "../views/ads/Index";
import Reports from "../views/Reports";
import Settings from "../views/Settings";
import Profile from "../views/Profile";
// Public
import notFound from "../views/404";

const routes = [
    // Auth
    {path: '/login', component: login, name: 'login', meta: {noAuth: true, title: 'Login'}},
    {path: '/activate', component: activate, name: 'activate', meta: {reqAuth: true, title: 'Activate License'}},

    // Pages
    {path: '/', name: 'dashboard', component: Dashboard, meta: {reqAuth: true, title: 'Dashboard'}},
    {path: '/users', name: 'users', component: Users, meta: {reqAuth: true, title: 'Users'}},
    {path: '/create-user', name: 'create-user', component: CreateUser, meta: {reqAuth: true, title: 'Create User'}},
    {
        path: '/user-details/:username',
        name: 'user-details',
        component: UserDetails,
        meta: {reqAuth: true, title: 'User Details'}
    },
    {path: '/dfy-message', name: 'dfy-status', component: DFYStatus, meta: {reqAuth: true, title: 'DFY Message Template'}},
    {path: '/create-dfy-message', name: 'create-dfy-status', component: CreateDFYStatus, meta: {reqAuth: true, title: 'DFY Message Template'}},
    {path: '/video-dfy-message', name: 'video-dfy-status', component: VideoDFYStatus, meta: {reqAuth: true, title: 'DFY Message Template'}},
    {path: '/youtube-dfy-message', name: 'youtube-dfy-status', component: YoutubeDFYStatus, meta: {reqAuth: true, title: 'DFY Message Template'}},
    {path: '/message', name: 'status', component: Status, meta: {reqAuth: true, title: 'Message'}},
    {
        path: '/status-details/:code',
        name: 'status-details',
        component: StatusDetails,
        meta: {reqAuth: true, title: 'Message Details'}
    },
    {
        path: '/status-edit/:code',
        name: 'status-edit',
        component: StatusEdit,
        meta: {reqAuth: true, title: 'Message Edit'}
    },
    {
        path: '/dfy-status-edit/:code',
        name: 'dfy-status-edit',
        component: DFYStatusEdit,
        meta: {reqAuth: true, title: 'Message Edit'}
    },
    {
        path: '/status-review',
        name: 'status-review',
        component: StatusReview,
        meta: {reqAuth: true, title: 'Message Review'}
    },
    {
        path: '/review-details/:code',
        name: 'review-details',
        component: ReviewDetails,
        meta: {reqAuth: true, title: 'Message Details'}
    },
    {path: '/categories', name: 'categories', component: Category, meta: {reqAuth: true, title: 'Categories'}},
    {path: '/slides', name: 'slides', component: Slides, meta: {reqAuth: true, title: 'Slides'}},
    {path: '/languages', name: 'languages', component: Languages, meta: {reqAuth: true, title: 'Languages'}},
    {path: '/currencies', name: 'currencies', component: Currencies, meta: {reqAuth: true, title: 'Currencies'}},
    {path: '/packages', name: 'packages', component: Packages, meta: {reqAuth: true, title: 'Packages'}},
    {path: '/edit-packages/:code', name: 'edit-packages', component: EditPackages, meta: {reqAuth: true, title: 'Edit Packages'}},
    {path: '/pages', name: 'pages', component: Pages, meta: {reqAuth: true, title: 'Pages'}},
    {path: '/deposits', name: 'deposits', component: Deposits, meta: {reqAuth: true, title: 'Deposits'}},
    {
        path: '/deposit-methods',
        name: 'deposit-methods',
        component: DepositMethods,
        meta: {reqAuth: true, title: 'Deposit Methods'}
    },
    {path: '/withdrawals', name: 'withdrawals', component: Withdrawals, meta: {reqAuth: true, title: 'Withdrawals'}},
    {
        path: '/withdrawal-methods',
        name: 'withdrawal-methods',
        component: WithdrawalMethods,
        meta: {reqAuth: true, title: 'Withdrawal Methods'}
    },
    {
        path: '/support-tickets',
        name: 'support-tickets',
        component: SupportTickets,
        meta: {reqAuth: true, title: 'Support Tickets'}
    },
    {path: '/ticket-details/:code', component: SupportMessages, name: 'ticket-details', meta: {reqAuth: true}},
    {path: '/reports', name: 'reports', component: Reports, meta: {reqAuth: true, title: 'Report'}},
    {path: '/ads', name: 'ads', component: Advertisement, meta: {reqAuth: true, title: 'Advertisements'}},
    {path: '/settings', name: 'settings', component: Settings, meta: {reqAuth: true, title: 'Settings'}},
    {path: '/profile', name: 'profile', component: Profile, meta: {reqAuth: true, title: 'profile'}},


    // 404
    {path: '/404', component: notFound, name: 'notFound', meta: {title: 'Page Not Found'}},
    {
        path: '*', beforeEnter: (to, from, next) => {
            next({path: '/404', replace: true})
        }
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0}
        }
    }
});
router.beforeEach(function (to, from, next) {
    if (to.meta.reqAuth && !store.getters.isLoggedIn) {
        next('/login')
    } else if (to.meta.noAuth && store.getters.isLoggedIn) {
        next('/')
    } else {
        next();
    }
});
export default router
