<template>
    <main v-if="infoLoading">
        <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-img />
            <content-placeholders-heading />
            <content-placeholders-heading />
        </content-placeholders>
    </main>
    <main v-else class="justify-center mx-auto w-full max-w-4xl">
        <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">

            <div class="form-control mt-5">
                <label class="label">
                    <span class="label-text font-bold">Name</span>
                </label>
                <input type="text" placeholder="Write a title..." class="input input-accent" v-model="template.name">
                <small class="text-red-600" v-if="errors.name">{{ errors.name[0] }}</small>
            </div>

            <div class="form-control mt-5">
                <label class="label">
                    <span class="label-text font-bold">Templates :</span>
                </label>
                <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 text-center">
                    <div class="truncate" v-for="temp in templates" :key="temp.id">
                        <label class="inline-flex items-center">
                            <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="temp.id"
                                v-model="template.templates">
                            <span class="label-text ml-1 md:ml-2">{{ temp.title }}</span>
                        </label>
                    </div>
                </div>
                <small class="text-red-600" v-if="errors.template">{{ errors.template[0] }}</small>
            </div>

            <div class="card p-2 shadow-lg compact bg-base-200 mx-1 mt-5">
                <div class="flex flex-row justify-around">
                    <div class="btn btn-success" @click="updatePackage">Update</div>
                </div>
            </div>

        </div>


    </main>
</template>

<script>
import axios from "axios";


export default {
    created() {
        this.getPackagesInfo();
    },
    mounted() {
        this.getPackagesInfo();
        this.$Progress.finish();
    },
    data() {
        return {
            url: this.$config.FILE_URL,
            template: {
                name: null,
                templates: [],
            },
            templates: [],
            errors: {},
            infoLoading: false,
            isUpdating: false,

        };
    },
    methods: {
        getPackagesInfo() {
            this.infoLoading = true;
            let code = this.$route.params.code;
            axios
                .get("/admin/templates-details", { params: { code: code } })
                .then(({ data }) => {
                    this.template= data.package;
                    this.template.templates = data.sc;
                    this.templates = data.templates;
                    this.infoLoading = false;
                })
                .catch((error) => {
                    if (error.response === 404) {
                        this.$router.replace({ name: "notFound" });
                    }
                    this.infoLoading = false;
                });
        },
        updatePackage() {
            this.isUpdating = true;
            let s = this.template;
            axios.patch('/admin/update-package', {
                id: s.id,
                name: s.name,
                templates: s.templates,
            })
                .then(() => {
                    this.isUpdating = false;
                    this.getPackagesInfo();
                    this.$noti('success', 'Updated Successfully');
                }).catch(() => {
                    this.isUpdating = false;
                    this.$noti('error', 'Something went wrong')
                })
        },
    },
};
</script>

<style scoped>
</style>
