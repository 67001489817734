<template>
  <main v-if="isLoading">
    <content-placeholders :centered="true" :rounded="true">
      <content-placeholders-heading/>
      <content-placeholders-heading/>
      <content-placeholders-heading/>
      <content-placeholders-heading/>
      <content-placeholders-heading/>
    </content-placeholders>
  </main>
  <main v-else class="justify-center mx-auto w-full">
    <div class="card p-2 shadow-lg compact bg-base-100 mx-1">
      <div class="divider mb-0">Inbox</div>
      <div id="container" ref="container"
           class="card rounded-t-none p-2 shadow-lg compact bg-base-100 mx-1 mt-1 h-52vh md:h-62vh"
           style="overflow-y: scroll" v-chat-scroll>
        <div>
          <div v-if="messages.length">
            <div class="flex justify-end" v-for="(message,index) in messages" :key="index">
              <div class="flex flex-col space-y-1 w-4/5 md:w-3/4">
                <div class="text-left mx-2">
                    <span class="text-xs font-thin">{{
                        message.created_at | timeDate
                      }}</span>
                </div>
                <div class="indicator w-full items-center mr-2">
                  <div
                      class="indicator-item indicator-top indicator-end mt-4"
                  >
                    <i class="bx bxs-right-arrow text-info bx-xs"></i>
                  </div>
                  <div
                      class="card w-full rounded p-2 shadow-lg compact bg-accent mx-1 mt-1"
                  >
                    {{ message.message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-col space-y-1  w-4/5 md:w-3/4">
              <div class="indicator w-full items-center ml-2">
                <div class="indicator-item indicator-middle indicator-start"><i
                    class="bx bxs-left-arrow text-success bx-xs"></i></div>
                <div class="card w-full rounded p-2 shadow-lg compact bg-success mx-1 mt-1">
                  No Messages to show !
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
        <div class="flex flex-row items-center mx-1 mt-2 mb-1 rounded">
          <div class="flex-grow">
            <textarea v-model="message_text" class="textarea h-12 w-full textarea-bordered textarea-accent"
                      placeholder="Write a message..."></textarea>
            <small v-if="errors.message" class="text-red-600">{{ errors.message[0] }}</small>
          </div>
          <div class="ml-2">
            <button :disabled="submitting" class="btn btn-primary btn-sm py-2" type="submit" @click="sendMessage">
              <svg-loading v-if="submitting" class="mr-1" height="16px" width="16px"/>
              Send
            </button>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {

  mounted() {
    this.getMessages();
    this.$Progress.finish();
  },
  data() {
    return {
      messages: {},
      message_text: null,
      errors: {},
      submitting: false,
      isLoading: false,
    };
  },
  methods: {
    async getMessages() {
      this.isLoading = true;
      let username = this.$route.params.username;
      try {
        let response = await axios.get("/admin/user-messages", {params: {username: username}});
        this.messages = response.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$noti("error", "Something Went Wrong !");
      }
    },

    async refreshMessages() {
      let username = this.$route.params.username;
      try {
        let response = await axios.get("/admin/user-messages", {params: {username: username}});
        this.messages = response.data;
      } catch (e) {
        this.$noti("error", "Something Went Wrong !");
      }
    },

    async sendMessage() {
      this.submitting = true;
      this.errors = {};
      let username = this.$route.params.username;
      try {
        let response = await axios.post("/admin/user-send-message", {username: username, message: this.message_text})
        this.submitting = false;
        this.message_text = null;
        this.$noti("success", response.data.message);
        await this.refreshMessages();
      } catch (e) {
        this.submitting = false;
        if (e.response.status === 406) {
          this.$noti("warning", e.response.data.message);
        } else {
          this.submitting = false;
          this.$noti("error", "Something Went Wrong !");
          this.errors = e.response.data.errors;
        }
      }
    },
  },
};
</script>
<style scoped>
#container {
  scroll-behavior: smooth;
}
</style>
