<template>
    <main class="justify-center mx-auto">
        <div class="w-full shadow-2xl">
            <div class="p-2 bg-base-100 mx-1">
                <div class="flex flex-col">
                    <div class="flex flex-row justify-between p-2 pb-0">
                        <div class="card-title">DFY Messages Template</div>
                        <router-link class="btn btn-accent btn-xs" to="/create-dfy-message">
                            Create Message
                        </router-link>
                        <router-link class="btn btn-accent btn-xs" to="/video-dfy-message">
                            Create Video Message
                        </router-link>
                        <router-link class="btn btn-accent btn-xs" to="/youtube-dfy-message">
                            Create From YouTube
                        </router-link>
                        <div class="btn btn-accent btn-xs" @click="getStatuses">Refresh</div>
                    </div>
                    <div class="divider mt-0">Filter</div>

                    <div class="flex flex-col md:flex-row justify-between space-y-2 px-2">

                        <div class="flex flex-col lg:flex-row justify-between space-y-3 space-x-5 px-2">

                            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
                                <div class="font-bold">Type :</div>
                                <div>
                                    <select class="select select-accent select-sm w-full max-w-xs" v-model="filter.type"
                                        @change="getStatuses">
                                        <option value="10" selected>All</option>
                                        <option value="image">Image</option>
                                        <option value="video">Video</option>
                                        <option value="youtube">YouTube</option>
                                    </select>
                                </div>
                            </div>

                            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
                                <div class="font-bold">SortBy :</div>
                                <div>
                                    <select class="select select-accent select-sm w-full max-w-xs"
                                        v-model="filter.sortBy" @change="getStatuses">
                                        <option value="desc" selected>Latest</option>
                                        <option value="asc">Oldest</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div class="flex flex-col lg:flex-row justify-between space-x-5 space-y-3 px-2">
                            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
                                <div class="font-bold">Per Page :</div>
                                <div>
                                    <input v-model="filter.per_page" class="input input-accent input-sm md:w-1/2 w-full"
                                        placeholder="Show per page" type="number" min="10" @change="getStatuses" />
                                </div>
                            </div>

                            <div class="flex flex-col md:flex-row items-center space-y-3 md:space-x-2">
                                <div>
                                    <input v-model="filter.search" class="input input-accent input-sm"
                                        placeholder="Search" type="text" />
                                </div>
                                <div class="btn btn-secondary btn-xs" @click="getStatuses">Search</div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <div class="card-body p-1 pb-6">
                <div v-if="isLoading" class="mt-5">
                    <content-placeholders :centered="true" :rounded="true">
                        <content-placeholders-heading />
                        <content-placeholders-heading />
                        <content-placeholders-heading />
                        <content-placeholders-heading />
                        <content-placeholders-heading />
                    </content-placeholders>
                </div>
                <div v-else>
                    <div v-if="statusData.data">
                        <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3">
                            <div v-for="(status, index) in statusData.data" :key="index">
                                <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2">
                                    <div class="flex flex-col justify-around space-y-2">
                                        <div class="avatar self-center mb-2">
                                            <div class="w-80 rounded">
                                                <figure>
                                                    <img v-if="status.thumbnail" :src="url + '/' + status.thumbnail" />
                                                    <img v-else :src="url + '/user/no-image.png'" />
                                                </figure>
                                            </div>
                                        </div>

                                        <div class="flex flex-row justify-between space-x-5 mx-1">
                                            <div class="font-bold">Title:</div>
                                            <div class="text-right">
                                                <h3 v-if="status.title && status.title.length < 80">
                                                    {{ status.title }}
                                                </h3>
                                                <h3 v-if="status.title && status.title.length > 80">
                                                    {{ status.title.substring(0, 80) + "..." }}
                                                </h3>
                                            </div>
                                        </div>

                                        <div class="flex flex-row justify-between space-x-3 mx-1">
                                            <div class="font-bold">Type :</div>
                                            <div>
                                                <div v-if="status.type == 'image'" class="badge badge-warning">
                                                    Image
                                                </div>
                                                <div v-if="status.type == 'video'" class="badge badge-success">
                                                    Video
                                                </div>
                                                <div v-if="status.type == 'youtube'" class="badge badge-error">
                                                    YouTube
                                                </div>
                                            </div>
                                        </div>

                                        <div class="flex flex-row justify-between space-x-3 mx-1"
                                            v-if="status.categories && status.categories.length">
                                            <div class="font-bold">Categories :</div>
                                            <div class="flex flex-wrap justify-end items-center space-x-2 space-y-1">
                                                <div class="badge badge-outline"
                                                    v-for="(category, index) in status.categories" :key="index">
                                                    {{ category.name }}
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">

                                        <router-link :to="{ name: 'dfy-status-edit', params: { code: status.code } }"
                                            class="btn btn-info btn-sm">
                                            <i class="bx bx-edit"></i>
                                        </router-link>

                                        <div class="btn btn-danger btn-sm" @click="deleteButtonClick(status.id, status.title)">
                                            <i class="bx bx-trash"></i>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="total == 0">
                        <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2">
                            <h2 class="font-bold text-lg">Nothing to Show</h2>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-2">
                    <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="statusData" :limit="3"
                        @pagination-change-page="getStatuses"></pagination>
                </div>
            </div>
        </div>

        <modal :if-close="true" :showing="deleteModal" @update:modal="deleteModal = $event">
            <div v-if="deleteModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
                <div class="divider">Are You Sure ?</div>
                <div class="text-2xl mt-5">
                    Delete : {{ deleteInfo.title }} !<span> </span>
                </div>
                <div class="form-control mt-8 mb-3">
                    <div class="flex flex-row space-x-5 justify-center">
                        <button :disabled="isDeleting" class="btn btn-outline" @click="deleteModal = false">
                            Cancel
                        </button>
                        <button :disabled="isDeleting" class="btn btn-error" @click="deleteDfyTemplate">
                            <svg-loading v-if="isDeleting" class="mr-1" height="16px" width="16px" />
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </modal>

    </main>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + " | " + this.$sitename;
    },
    mounted() {
        if (this.$store.state.last_route != this.$route.path || !this.statusData.data.length) {
            this.getStatuses(1);
        }
        this.$Progress.finish();
    },

    data() {
        return {
            url: this.$config.FILE_URL,
            total: null,
            filter: {
                type: '10',
                per_page: 12,
                sortBy: 'desc',
                search: null,
            },
            isLoading: false,
            isDeleting: false,
            errors: {},
            deleteModal: false,
        };
    },
    computed: {
        ...mapGetters({
            statusData: 'dfyStatuses'
        })
    },
    methods: {
        ...mapActions({
            getStatusData: 'getDfyStatuses',
            setRoute: 'lastRouteAction'
        }),
        getStatuses(page) {
            this.isLoading = true;
            let type = this.filter.type;
            if (type !== '') {
                if (type == 10) {
                    type = ''
                }
            }
            let sortBy = this.filter.sortBy;
            let cp = page ? page : this.statusData.current_page;
            let urlData = {
                params: {
                    type: type,
                    sortBy: sortBy,
                    per_page: this.filter.per_page,
                    search: this.filter.search,
                    page: cp
                }
            };
            
            try {
                this.setRoute(this.$route.path);
            } finally {
                this.getStatusData(urlData).then(() => {
                    this.total = this.statusData.total;
                    this.isLoading = false;
                }).catch(() => {
                    this.isLoading = false;
                });
            }
        },
         deleteButtonClick(id, title) {
            this.deleteInfo = { id: id, title: title };
            this.deleteModal = true;
        },
        deleteDfyTemplate() {
            this.isDeleting = true;
            this.errors = {};
            axios
                .post("/admin/dfy-delete-template", { id: this.deleteInfo.id })
                .then(({ data }) => {
                    this.isDeleting = false;
                    this.getStatuses(1);
                    this.deleteModal = false;
                    this.deleteInfo = {};
                    this.$noti("success", data.message);
                })
                .catch((error) => {
                    this.isDeleting = false;
                    if (error.response.status === 404 || error.response.status === 406 || error.response.status === 400) {
                        this.$noti("warning", error.response.data.message);
                    } else {
                        this.errors = error.response.data.errors;
                        this.$noti("error", "Something Went Wrong !");
                    }
                });
        },
    },
};
</script>

<style scoped>
</style>