<template>
    <main class="justify-center mx-auto">
        <div class="mx-2 mt-3">
            <div class="flex flex-row w-full justify-between">
                <div :class="formType === 1 ? 'tab-active' : ''" class="flex-grow tab tab-bordered"
                    @click="tabClick(1)">
                    Packages
                </div>
                <div :class="formType === 2 ? 'tab-active' : ''" class="flex-grow tab tab-bordered"
                    @click="tabClick(2)">
                    Add Package
                </div>
            </div>
        </div>

        <div v-if="formType === 1">
            <div class="w-full shadow-2xl">
                <div class="card-body p-1 pb-6">
                    <div v-if="isLoading" class="mt-5">
                        <content-placeholders :centered="true" :rounded="true">
                            <content-placeholders-heading />
                            <content-placeholders-heading />
                            <content-placeholders-heading />
                            <content-placeholders-heading />
                            <content-placeholders-heading />
                        </content-placeholders>
                    </div>
                    <div v-else>
                        <div v-if="packages.length" class="mt-5">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-x-3">
                                <div v-for="(pkg, index) in packages" :key="index">
                                    <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2">

                                        <div class="flex flex-row justify-between space-x-5 mx-1">
                                            <div class="font-bold">Name :</div>
                                            <div class="text-right">{{ pkg.name }}</div>
                                        </div>
                                        <div class="flex flex-row justify-between space-x-5 mx-1">
                                            <div class="font-bold">Key :</div>
                                            <div class="text-right text-sm">{{ pkg.uuid }}</div>
                                        </div>

                                        <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">
                                            <router-link
                                                :to="{ name: 'edit-packages', params: { code: pkg.uuid } }"
                                                class="btn btn-info btn-sm">
                                                <i class="bx bx-edit"></i>
                                            </router-link>
                                            <div class="btn btn-sm"
                                                :class="pkg.id === 1 ? 'btn-disabled' : 'btn-primary'"
                                                @click="deleteButtonClick(pkg.id, pkg.name)">
                                                <i class="bx bx-trash"></i>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2">
                                <h2 class="font-bold text-lg">Nothing to Show</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="formType === 2">
            <div class="pt-4 w-full justify-center mx-auto md:w-5/6 shadow-2xl">
                <div class="card-body pt-0">
                    <form @submit.prevent="createPackage">

                        <div class="form-control mt-5">
                            <label class="label">
                                <span class="label-text font-bold">Name : </span>
                            </label>
                            <input v-model="form.name" class="input input-accent" placeholder="Ex: FE" type="text" />
                            <small v-if="errors.name" class="text-red-600">{{ errors.name[0] }}</small>
                        </div>

                        <div class="form-control mt-5">
                            <label class="label">
                                <span class="label-text font-bold">DFY Template :</span>
                            </label>
                            <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
                                <div class="truncate" v-for="template in templates" :key="template.id">
                                    <label class="inline-flex items-center">
                                        <input type="checkbox" checked="checked" class="checkbox checkbox-accent"
                                            :value="template.id" v-model="form.templates">
                                        <span class="label-text ml-1 md:ml-2">{{ template.title }}</span>
                                    </label>
                                </div>
                            </div>
                            <small class="text-red-600" v-if="errors.templates">{{ errors.templates[0] }}</small>
                        </div>


                        <div class="form-control mt-8">
                            <button :disabled="isCreating" class="btn btn-primary" type="submit">
                                <svg-loading v-if="isCreating" class="mr-1" height="16px" width="16px" />
                                Create Package
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <modal :if-close="true" :showing="editModal" @update:modal="editModal = $event">
            <div v-if="editModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
                <div class="divider">Edit Details</div>

                <form @submit.prevent="updatePackage">

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Name : </span>
                        </label>
                        <input v-model="editForm.name" class="input input-accent" placeholder="Ex: FE" type="text" />
                        <small v-if="errors.name" class="text-red-600">{{ errors.name[0] }}</small>
                    </div>

                    <div class="form-control mt-8 mb-3">
                        <button :disabled="isUpdating" class="btn btn-primary" type="submit">
                            <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px" />
                            Update Package
                        </button>
                    </div>
                </form>
            </div>
        </modal>

        <modal :if-close="true" :showing="deleteModal" @update:modal="deleteModal = $event">
            <div v-if="deleteModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
                <div class="divider">Are You Sure ?</div>
                <div class="text-2xl mt-5">
                    Delete : {{ deleteInfo.name }} !<span> </span>
                </div>
                <div class="form-control mt-8 mb-3">
                    <div class="flex flex-row space-x-5 justify-center">
                        <button :disabled="isDeleting" class="btn btn-outline" @click="deleteModal = false">
                            Cancel
                        </button>
                        <button :disabled="isDeleting" class="btn btn-error" @click="deletePackage">
                            <svg-loading v-if="isDeleting" class="mr-1" height="16px" width="16px" />
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </modal>

    </main>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + " | " + this.$sitename;
    },
    created() {
        this.getPackages();
        this.getTemplate();
    },
    mounted() {
        this.$Progress.finish();
    },
    data() {
        return {
            url: this.$config.FILE_URL,
            formType: 1,
            packages: [],
            templates: [],
            form: {
                name: null,
                templates: [],
            },
            editForm: {
                id: null,
                name: null,
                templates: [],
            },
            deleteInfo: {
                id: null,
                name: null,
            },
            isLoading: false,
            isCreating: false,
            isUpdating: false,
            isDeleting: false,
            errors: {},
            editModal: false,
            deleteModal: false,
        };
    },
    methods: {
        getTemplate() {
            axios.get('/admin/templates')
                .then(({ data }) => {
                    this.templates = data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log('Something went wrong')
                })
        },
        getPackages() {
            this.isLoading = true;
            axios
                .get("/admin/packages")
                .then(({ data }) => {
                    this.packages = data;
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.errors = error.response.data.errors;
                });
        },
        tabClick(id) {
            if (this.formType == 1 && id == 1) {
                this.getPackages();
            }
            this.formType = id;
        },
        editClickAction(data) {
            try {
                this.editForm = {
                    id: data.id,
                    name: data.name,
                    templates: data.dfyStatus
                };
            } finally {
                this.editModal = true;
            }
        },
        createPackage() {
            this.isCreating = true;
            this.errors = {};
            axios
                .post("/admin/create-package", this.form)
                .then(({ data }) => {
                    this.isCreating = false;
                    this.resetForm();
                    this.getPackages();
                    this.$noti("success", data.message);
                })
                .catch((error) => {
                    this.isCreating = false;
                    if (error.response.status === 406) {
                        this.$noti("warning", error.response.data.message);
                    } else {
                        this.errors = error.response.data.errors;
                        this.$noti("error", "Something Went Wrong !");
                    }
                });
        },
        updatePackage() {
            this.isUpdating = true;
            this.errors = {};
            axios
                .patch("/admin/update-package", this.editForm)
                .then(({ data }) => {
                    this.isUpdating = false;
                    this.editForm = {
                        id: null,
                        name: null,
                    };
                    this.getPackages();
                    this.editModal = false;
                    this.$noti("success", data.message);
                })
                .catch((error) => {
                    this.isUpdating = false;
                    if (error.response.status === 404) {
                        this.$noti("warning", error.response.data.message);
                    } else {
                        this.errors = error.response.data.errors;
                        this.$noti("error", "Something Went Wrong !");
                    }
                });
        },
        deletePackage() {
            this.isDeleting = true;
            this.errors = {};
            axios
                .post("/admin/delete-package", { id: this.deleteInfo.id })
                .then(({ data }) => {
                    this.isDeleting = false;
                    this.getPackages();
                    this.deleteModal = false;
                    this.deleteInfo = {};
                    this.$noti("success", data.message);
                })
                .catch((error) => {
                    this.isDeleting = false;
                    if (error.response.status === 404 || error.response.status === 406 || error.response.status === 400) {
                        this.$noti("warning", error.response.data.message);
                    } else {
                        this.errors = error.response.data.errors;
                        this.$noti("error", "Something Went Wrong !");
                    }
                });
        },
        deleteButtonClick(id, name) {
            this.deleteInfo = { id: id, name: name };
            this.deleteModal = true;
        },

        resetForm() {
            this.form = { name: null };
        },

        scrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>

<style scoped>
</style>