<template>
  <main class="justify-center mx-auto">

    <main v-if="firstLoading">
      <content-placeholders :rounded="true" :centered="true">
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
      </content-placeholders>
    </main>
    <main v-else>
      <div class="w-full">
        <div class="grid p-4 py-6 shadow-xl bg-base-100 rounded-box place-items-center w-full">
          <div class="indicator">
            <div v-if="user.verified" class="pt-2 mr-3 indicator-item">
              <verification-svg height="25px" width="25px"/>
            </div>
            <div class="avatar">
              <div v-if="user.image" class="w-24 h-24 p-px mask mask-squircle bg-base-content bg-opacity-10"><img
                  :src="url + '/'+ user.image" width="94" height="94"
                  :alt="user.name" class="mask mask-squircle"></div>
              <div v-else class="w-24 h-24 p-px mask mask-squircle bg-base-content bg-opacity-10"><img
                  :src="url + '/user/no-image.png'" width="94" height="94"
                  :alt="user.name" class="mask mask-squircle"></div>
            </div>
          </div>
          <div class="text-center">

            <div class="text-lg font-extrabold">{{ user.name }}</div>
            <div class="text-md text-opacity-70">@{{ user.username }}</div>
            <div class="mt-2 mb-3 text-sm text-base-content text-opacity-60">
              {{ user.bio }}
            </div>
          </div>

        </div>
      </div>
      <div
          class="flex flex-row w-full shadow-xl bg-base-100 justify-between rounded-box text-center mt-1 px-2 py-2 md:px-4 md:py-4">
        <div class="tabs">
          <div :class="formType === 1 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(1)">
            Details
          </div>
          <div :class="formType === 2 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(2)">
            Messages
          </div>
          <div :class="formType === 3 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(3)">
            Following <span class="mx-1" v-if="info.followings">({{ info.followings | formatNumber }})</span></div>
          <div :class="formType === 4 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(4)">
            Followers <span class="mx-1" v-if="info.followers">({{ info.followers | formatNumber }})</span></div>
          <div :class="formType === 5 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(5)">
            Status <span class="mx-1" v-if="info.status">({{ info.status | formatNumber }})</span></div>
          <div :class="formType === 6 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(6)">
            Deposits <span class="mx-1" v-if="info.deposits">({{ info.deposits | formatNumber }})</span></div>
          <div :class="formType === 7 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(7)">
            Withdrawals <span class="mx-1" v-if="info.withdrawals">({{ info.withdrawals | formatNumber }})</span></div>
          <div :class="formType === 8 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(8)">
            Transactions
          </div>
          <div :class="formType === 9 ? 'tab-active' : ''" class="tab md:tab-lg tab-bordered" @click="tabClick(9)">
            Referrals <span class="mx-1" v-if="info.referrals">({{ info.referrals | formatNumber }})</span></div>
        </div>
      </div>
    </main>

    <main class="mt-3">
      <div v-if="formType === 1">
        <update-info/>
      </div>

      <div v-if="formType === 2">
        <Messages/>
      </div>

      <div v-if="formType === 3">
        <Follow :type="2"/>
      </div>

      <div v-if="formType === 4">
        <Follow :type="1"/>
      </div>

      <div v-if="formType === 5">
        <Status/>
      </div>

      <div v-if="formType === 6">
        <Deposits/>
      </div>

      <div v-if="formType === 7">
        <Withdrawals/>
      </div>

      <div v-if="formType === 8">
        <Transactions/>
      </div>

      <div v-if="formType === 9">
        <Referrals/>
      </div>

    </main>

  </main>
</template>

<script>
import UpdateInfo from "./UpdateInfo";
import Messages from "./Messages";
import Follow from "./Follow";
import Status from "../status/Index";
import Deposits from "../Deposits";
import Withdrawals from "../Withdrawals";
import Transactions from "./Transactions";
import Referrals from "./Referrals";
import axios from "axios";

export default {
  components: {UpdateInfo, Messages, Follow, Status, Deposits, Withdrawals, Transactions, Referrals},
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.$Progress.finish();
  },
  watch: {
    $route() {
      this.formType = 1;
      this.getInfo();
      this.$Progress.finish();
    }
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      formType: 1,
      user: {},
      info: {},
      firstLoading: false,
      isLoading: false,
      errors: {},
    };
  },
  methods: {
    async getInfo() {
      this.firstLoading = true;
      let username = this.$route.params.username;
      try {
        let response = await axios.get("/admin/user-details", {params: {username: username}});
        this.user = response.data.user;
        this.info = response.data.info;
        this.firstLoading = false;
      } catch (e) {
        this.firstLoading = false;
        this.errors = e.response.data.errors;
      }
    },

    tabClick(id) {
      this.formType = id;
    },

  },
};
</script>

<style scoped></style>