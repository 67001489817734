<template>
  <main class="justify-center mx-auto">

    <main v-if="infoLoading">
      <content-placeholders :rounded="true" :centered="true">
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
      </content-placeholders>
    </main>
    <main v-else>
      <div class="w-full">
        <div class="grid p-4 py-6 shadow-xl bg-base-100 rounded-box place-items-center w-full">

          <div class="avatar">
            <div v-if="user.image" class="w-24 h-24 p-px mask mask-squircle bg-base-content bg-opacity-10"><img
                :src="url + '/'+ user.image" width="94" height="94"
                :alt="user.name" class="mask mask-squircle"></div>
            <div v-else class="w-24 h-24 p-px mask mask-squircle bg-base-content bg-opacity-10"><img
                :src="url + '/user/no-image.png'" width="94" height="94"
                :alt="user.name" class="mask mask-squircle"></div>
          </div>

          <div class="text-center">

            <div class="text-lg font-extrabold">{{ user.name }}</div>
            <div class="text-md text-opacity-70">@{{ user.username }}</div>
          </div>

        </div>
      </div>

      <div class="mx-2 mt-3">
        <div class="tabs tabs-boxed">
          <div
              :class="formType === 1 ? 'tab-active' : ''"
              class="flex-grow tab"
              @click="formType = 1"
          >
            Edit Info
          </div>
          <div
              :class="formType === 2 ? 'tab-active' : ''"
              class="flex-grow tab"
              @click="formType = 2"
          >
            Update Password
          </div>
          <div
              :class="formType === 3 ? 'tab-active' : ''"
              class="flex-grow tab"
              @click="formType = 3"
          >
            Update Image
          </div>
        </div>
      </div>


    </main>

    <main class="mt-3">
      <div v-if="formType === 1">
        <div class="w-full shadow-2xl">
          <div class="card-body pt-0">
            <div class="mt-5" v-if="infoLoading">
              <content-placeholders :rounded="true" :centered="true">
                <content-placeholders-heading/>
                <content-placeholders-heading/>
                <content-placeholders-heading/>
                <content-placeholders-heading/>
                <content-placeholders-heading/>
                <content-placeholders-heading/>
                <content-placeholders-heading/>
              </content-placeholders>
            </div>
            <div v-else>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                <div class="form-control mt-5">
                  <label class="label">
                    <span class="label-text font-bold">Full Name</span>
                  </label>
                  <input
                      type="text"
                      placeholder="Full Name"
                      class="input input-accent"
                      v-model="form.name"
                  />
                  <small class="text-red-600" v-if="errors.name">{{
                      errors.name[0]
                    }}</small>
                </div>

                <div class="form-control mt-2 md:mt-5">
                  <label class="label">
                    <span class="label-text font-bold">Username</span>
                  </label>
                  <input
                      type="text"
                      placeholder="username"
                      class="input input-accent"
                      v-model="form.username"
                  />
                  <small class="text-red-600" v-if="errors.username">{{
                      errors.username[0]
                    }}</small>
                </div>
                <div class="form-control mt-2">
                  <label class="label">
                    <span class="label-text font-bold">Email</span>
                  </label>
                  <input
                      type="email"
                      placeholder="Email Address"
                      class="input input-accent"
                      v-model="form.email"
                  />
                  <small class="text-red-600" v-if="errors.email">{{
                      errors.email[0]
                    }}</small>
                </div>

                <div>

                </div>


              </div>
              <div class="flex justify-center">
                <div class="form-control mt-6 item md:w-1/2">
                  <button
                      @click="updateInfo"
                      class="btn btn-primary"
                      :disabled="isUpdating"
                  >
                    <svg-loading
                        height="16px"
                        width="16px"
                        class="mr-1"
                        v-if="isUpdating"
                    />
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="formType === 2">
        <div class="pt-4 w-full shadow-2xl">
          <div class="card-body pt-0">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <div class="form-control mt-5">
                <label class="label">
                  <span class="label-text font-bold">New Password</span>
                </label>
                <input
                    type="password"
                    placeholder="New Password"
                    class="input input-accent"
                    v-model="newPass.password"
                />
                <small class="text-red-600" v-if="errors.password">{{
                    errors.password[0]
                  }}</small>
              </div>
              <div class="form-control mt-2 md:mt-5">
                <label class="label">
                  <span class="label-text font-bold">Confirm Password</span>
                </label>
                <input
                    type="password"
                    placeholder="Confirm Password"
                    class="input input-accent"
                    v-model="newPass.password_confirmation"
                />
                <small class="text-red-600" v-if="errors.password_confirmation">{{
                    errors.password_confirmation[0]
                  }}</small>
              </div>
            </div>
            <div class="flex justify-center">
              <div class="form-control mt-8 item md:w-1/2">
                <button
                    @click="updatePass"
                    class="btn btn-primary"
                    :disabled="isPassUpdating"
                >
                  <svg-loading
                      height="16px"
                      width="16px"
                      class="mr-1"
                      v-if="isPassUpdating"
                  />
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="formType === 3">
        <div class="pt-6 w-full shadow-2xl">
          <div class="card-body pt-0">
            <form @submit.prevent="updateImage">
              <div class="form-control mt-5">
                <input
                    type="file"
                    accept=".jpg,.png,.jpeg,.heic"
                    class="file"
                    @change="imageChange"
                />
                <small class="text-red-600" v-if="errors.image">{{
                    errors.image[0]
                  }}</small>
              </div>
              <div class="form-control mt-5" v-if="image">
                <div class="flex flex-wrap justify-center">
                  <div class="w-8/12 sm:w-6/12 px-4">
                    <img
                        :src="image"
                        alt="..."
                        class="rounded max-w-full h-auto align-middle border-none"
                    />
                  </div>
                </div>
              </div>

              <div class="mt-4 p-6" v-if="uploading">
                <progress
                    class="progress progress-accent"
                    :value="uploadPercentage"
                    max="100"
                ></progress>
              </div>

              <div class="form-control mt-8">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="uploading"
                >
                  <svg-loading
                      height="16px"
                      width="16px"
                      class="mr-1"
                      v-if="uploading"
                  />
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </main>

  </main>
</template>

<script>
import axios from "axios";

export default {

  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getInfo();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      formType: 1,
      user: {},
      form: {
        name: null,
        username: null,
        email: null
      },
      newPass: {
        password: "",
        password_confirmation: "",
      },
      image: null,
      uploading: false,
      uploadPercentage: 0,
      errors: {},
      loading: false,
      isUpdating: false,
      isPassUpdating: false,
      infoLoading: false,
    }
  },
  methods: {
    async getInfo() {
      this.infoLoading = true;
      let username = this.$route.params.username;
      try {
        let response = await axios.get("/admin/my-info");
        this.user = response.data;
        this.form = response.data;
        this.infoLoading = false;
      } catch (e) {
        this.infoLoading = false;
      }
    },

    async updateInfo() {
      this.isUpdating = true;
      this.errors = {};
      try {
        let response = await this.$axios.patch('/admin/my-info', this.form)
        this.isUpdating = false;
        this.$noti('success', 'Info Updated');
      } catch (e) {
        this.isUpdating = false;
        this.errors = e.response.data.errors
        this.$noti('error', 'Something Went Wrong')
      }
    },
    async updatePass() {
      this.isPassUpdating = true;
      this.errors = {};
      try {
        let response = await this.$axios.patch('/admin/update-pass', this.newPass)
        this.isPassUpdating = false;
        this.$noti('success', 'Pass Updated');
        await this.$store.dispatch('clearData');
        await this.$router.push('/login');
      } catch (e) {
        this.isPassUpdating = false;
        this.errors = e.response.data.errors
        this.$noti('error', 'Something Went Wrong')
      }
    },
    async updateImage() {
      this.uploading = true;
      this.errors = {};
      try {
        let response = await axios.post('/admin/update-image', {image: this.image}, {
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          }.bind(this)
        })
        this.uploading = false;
        this.image = null;
        this.$noti('success', '' + response.data.message + '')

      } catch (e) {
        this.uploading = false;
        this.$noti('error', 'Something Went Wrong')
      }
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.image = event.target.result
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style scoped></style>