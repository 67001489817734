<template>
  <main class="justify-center mx-auto w-full">
    <!-- User Posts -->
    <div class="mx-1 my-4" v-if="isLoading">
      <content-placeholders :rounded="true" :centered="true">
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
        <content-placeholders-img/>
        <content-placeholders-heading/>
        <content-placeholders-heading/>
      </content-placeholders>
    </div>
    <div v-else>
      <main class="flex flex-col space-y-3 mx-1 mt-3 md:mt-0">

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-2">

          <router-link :to="{name: 'status-review'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Review Message</div>
                <div class="stat-value text-warning">{{ pending.status | formatNumber }}</div>
              </div>
              <div>
                <div class="text-warning">
                  <i class="bx bxs-book-reader text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'ads'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Pending Ads</div>
                <div class="stat-value text-warning">{{ pending.ads | formatNumber }}</div>
              </div>
              <div>
                <div class="text-warning">
                  <i class="bx bx-dollar text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'withdrawals'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Pending Withdrawals</div>
                <div class="stat-value text-warning">{{ pending.withdrawals | formatNumber }}</div>
              </div>
              <div>
                <div class="text-warning">
                  <i class="bx bx-money-withdraw text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'deposits'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Pending Deposits</div>
                <div class="stat-value text-warning">{{ pending.deposits | formatNumber }}</div>
              </div>
              <div>
                <div class="text-warning">
                  <i class="bx bxs-bank text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <!-- <router-link :to="{name: 'support-tickets'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Active Tickets</div>
                <div class="stat-value text-warning">{{ pending.tickets | formatNumber }}</div>
              </div>
              <div>
                <div class="text-warning">
                  <i class="bx bx-support text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link> -->


        </div>

        <div class="divider py-4">Total Statistics</div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-2 gap-y-2">

          <router-link :to="{name: 'users'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Users</div>
                <div class="stat-value text-secondary">{{ info.users | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-group text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'status'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Messages</div>
                <div class="stat-value text-secondary">{{ info.status | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-book-content text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'status'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Images</div>
                <div class="stat-value text-secondary">{{ info.images | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-image text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'status'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Videos</div>
                <div class="stat-value text-secondary">{{ info.videos | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-videos text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'status'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Youtube</div>
                <div class="stat-value text-secondary">{{ info.youtube | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxl-youtube text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'deposits'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Deposits</div>
                <div class="stat-value text-secondary">{{ info.deposits | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-bank text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'deposit-methods'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Deposit Methods</div>
                <div class="stat-value text-secondary">{{ info.deposit_methods | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-wallet-alt text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'withdrawals'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Withdrawals</div>
                <div class="stat-value text-secondary">{{ info.withdrawals | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-money-withdraw text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'withdrawal-methods'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Withdrawal Methods</div>
                <div class="stat-value text-secondary">{{ info.withdrawal_methods | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-wallet text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'ads'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Advertisements</div>
                <div class="stat-value text-secondary">{{ info.ads }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-dollar text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'packages'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Package</div>
                <div class="stat-value text-secondary">{{ info.tickets }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-package text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'reports'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Reports</div>
                <div class="stat-value text-secondary">{{ info.reports }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-report text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'slides'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Slides</div>
                <div class="stat-value text-secondary">{{ info.slides }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-slideshow text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'categories'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Categories</div>
                <div class="stat-value text-secondary">{{ info.categories | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-category text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'currencies'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Currencies</div>
                <div class="stat-value text-secondary">{{ info.currencies | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bxs-money text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>

          <router-link :to="{name: 'languages'}" class="card px-5 py-4 shadow-lg compact bg-base-100">
            <div class="flex flex-row justify-between items-center">
              <div>
                <div class="stat-title text-lg">Languages</div>
                <div class="stat-value text-secondary">{{ info.languages | formatNumber }}</div>
              </div>
              <div>
                <div class="text-secondary">
                  <i class="bx bx-globe text-2xl md:text-3xl"></i>
                </div>
              </div>
            </div>
          </router-link>


        </div>


      </main>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    this.getInfo();
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      pending: {},
      info: {},
      isLoading: false,
    };
  },
  methods: {
    async getInfo() {
      this.isLoading = true;
      try {
        let r = await axios.get("/admin/dashboard-info");
        this.pending = r.data.pending;
        this.info = r.data.info;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$noti("error", "Something Went Wrong");
      }
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>