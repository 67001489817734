<template>
    <main class="justify-center mx-auto mt-3 mb-4 sm:w-4/5 md:w-3/4 lg:w-4/6 overflow-hidden">

        <div class="pt-6 w-full shadow-2xl">

            <div class="card-body pt-0">

                <form @submit.prevent="uploadImage">
                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Full Name : </span>
                        </label>
                        <input type="text" placeholder="Full Name" class="input input-accent" v-model="form.name">
                        <small class="text-red-600" v-if="errors.name">{{ errors.name[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Username : </span>
                        </label>
                        <input type="text" placeholder="Username" class="input input-accent" v-model="form.username">
                        <small class="text-red-600" v-if="errors.username">{{ errors.username[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Email : </span>
                        </label>
                        <input type="text" placeholder="Email" class="input input-accent" v-model="form.email">
                        <small class="text-red-600" v-if="errors.email">{{ errors.email[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Select Gender : </span>
                        </label>
                        <select class="select select-bordered select-accent" v-model="form.gender">
                            <option value="" disabled="disabled" selected>Select Gender</option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                            <option value="3">Others</option>
                        </select>
                        <small class="text-red-600" v-if="errors.gender">{{ errors.gender[0] }}</small>
                    </div>

                    <div class="form-control mt-2">
                        <label class="label">
                            <span class="label-text font-bold">Mobile Number : </span>
                        </label>
                        <input type="tel" placeholder="Mobile Number" class="input input-accent" v-model="form.phone">
                        <small class="text-red-600" v-if="errors.phone">{{ errors.phone[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Password : </span>
                        </label>
                        <input type="password" placeholder="Password" class="input input-accent"
                            v-model="form.password">
                        <small class="text-red-600" v-if="errors.password">{{ errors.password[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Choose Country : </span>
                        </label>
                        <select class="select select-accent" v-model="form.country_code">
                            <option value="" disabled="disabled" selected="selected">Choose Country</option>
                            <option :value="country.code" v-for="country in countries">{{
                                    country.name
                            }}
                            </option>
                        </select>
                        <small class="text-red-600" v-if="errors.country_code">{{ errors.country_code[0] }}</small>
                    </div>

                    <div class="form-control mt-5">
                        <label class="label">
                            <span class="label-text font-bold">Select Package : </span>
                        </label>
                        <select class="select select-bordered select-accent" v-model="form.plan">
                            <option value="" disabled="disabled" selected>Select Package</option>
                            <option value="FE">FE</option>
                            <option value="DFY">DFY</option>
                            <option value="UNLIMITED">UNLIMITED</option>
                            <option value="PROFIT BOOSTER">PROFIT BOOSTER</option>
                        </select>
                        <small class="text-red-600" v-if="errors.gender">{{ errors.gender[0] }}</small>
                    </div>

                    <div class="mt-4 p-6" v-if="uploading">
                        <progress class="progress progress-accent" :value="uploadPercentage" max="100"></progress>
                    </div>

                    <div class="form-control mt-8 mb-3" :class="uploading ? 'disabledArea' : ''">
                        <button type="submit" class="btn btn-primary">
                            <svg-loading height="16px" width="16px" class="mr-1" v-if="uploading" />
                            Submit
                        </button>
                    </div>
                </form>
            </div>

        </div>

    </main>
</template>

<script>
import axios from "axios";

export default {
    beforeCreate() {
        document.title = this.$route.meta.title + ' | ' + this.$sitename;
    },
    created() {
        // this.getUploadInfo();
        this.$axios.get('/country-list')
            .then(({ data }) => (this.countries = data))
    },
    mounted() {
        this.$Progress.finish();
    },
    data() {
        return {
            form: {
                name: null,
                username: null,
                email: null,
                phone: null,
                gender: "",
                plan: "",
                country_code: "",
                password: null,
            },
            uploading: false,
            uploadPercentage: 0,
            errors: {},
            countries: {},
        }
    },
    methods: {
        uploadImage() {
            this.uploading = true;
            axios.post('/admin/create-user', this.form, {
                onUploadProgress: function (progressEvent) {
                    this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(this)
            })
                .then(({ data }) => {
                    this.uploading = false;
                    this.uploadPercentage = 0;
                    this.form = { name: null, username: null, email: null, phone: null, gender: "", plan: "", country_code: "", password: null, };
                    this.$noti('success', '' + data.message + '')
                    this.$router.replace({ name: 'users' })
                })
                .catch((error) => {
                    this.uploading = false;
                    this.uploadPercentage = 0;
                    this.errors = error.response.data.errors;
                    this.$noti('error', 'Something Went Wrong')
                })
        },
    },
}
</script>

<style scoped>
</style>