<template>
  <main v-if="infoLoading">
    <content-placeholders :centered="true" :rounded="true">
      <content-placeholders-img />
      <content-placeholders-heading />
      <content-placeholders-heading />
    </content-placeholders>
  </main>
  <main v-else class="justify-center mx-auto w-full max-w-4xl">
    <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
      <figure>
        <img v-if="status.media.url && status.type === 'image'" :alt="status.title" :class="
          fullImage
            ? 'status-image-full'
            : 'status-image md:status-image-desktop'
        " :src="url + status.media.url" class="object-fill w-full rounded" @click="toggleHeight" />

        <vue-plyr v-if="status.media.url && status.type === 'video'">
          <video :data-poster="url + status.thumb" controls>
            <source :src="url + status.media.url" type="video/mp4" />
          </video>
        </vue-plyr>

        <vue-plyr v-if="status.media.url && status.type === 'youtube'">
          <div :data-plyr-embed-id="status.media.ext" data-plyr-provider="youtube"></div>

        </vue-plyr>
      </figure>

      <div class="p-2 pb-1">
        <h1 class="text-base font-medium">{{ status.title }}</h1>
      </div>
    </div>

    <div v-if="status.user" class="card p-2 compact bg-base-100 mx-1 mt-1">
      <div class="flex-row items-center space-x-2.5 card px-1 pb-1">
        <div class="flex-1">
          <div class="flex-row items-center space-x-3 card mt-1 mb-1">
            <div class="avatar">
              <div class="rounded-full w-10 h-10 shadow clickable" @click="viewUser(status.user.username)">
                <img v-if="status.user.image" :src="url + '/' + status.user.image" />
                <img v-else :src="url + '/user/no-image.png'" />
              </div>
            </div>
            <div class="flex flex-col space-y-0 items-start">
              <div class="flex flex-row space-x-1.5 items-center clickable" @click="viewUser(status.user.username)">
                <div class="font-bold text-lg ml-1">
                  {{ status.user.name }}
                </div>
                <div v-if="status.user.verified">
                  <verification-svg height="17px" width="17px" />
                </div>
              </div>
              <div class="text-sm font-thin text-opacity-60 ml-1">
                @{{ status.user.username }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-0">
          <div class="flex-row items-center space-x-2.5 card mt-1 mb-1">
            <router-link :to="{
              name: 'user-details',
              params: { username: status.user.username },
            }" class="btn-secondary btn-sm">View Profile
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text font-bold">Title</span>
        </label>
        <input type="text" placeholder="Write a title..." class="input input-accent" v-model="status.title">
        <small class="text-red-600" v-if="errors.title">{{ errors.title[0] }}</small>
      </div>
      <div class="form-control mt-3">
        <label class="label">
          <span class="label-text font-bold">Description :</span>
        </label>
        <textarea class="textarea h-24 textarea-bordered textarea-accent"
          placeholder="Write a description about the status" v-model="status.description"></textarea>
        <small class="text-red-600" v-if="errors.description">{{ errors.description[0] }}</small>
      </div>

      <div class="form-control mt-5">
        <div class="flex flex-row justify-between">
          <div class="label">
            <span class="label-text font-bold">Allow Comments :</span>
          </div>
          <div class="flex mr-2 items-center">
            <input type="checkbox" checked="checked" class="toggle toggle-accent" v-model="status.allow_comments">
          </div>
        </div>
        <small class="text-red-600" v-if="errors.allow_comments">{{ errors.allow_comments[0] }}</small>
      </div>

      <div class="form-control mt-5">
        <div class="flex flex-row justify-between">
          <div class="label">
            <span class="label-text font-bold">Enabled :</span>
          </div>
          <div class="flex mr-2 items-center">
            <input type="checkbox" checked="checked" class="toggle toggle-accent" v-model="status.enabled">
          </div>
        </div>
        <small class="text-red-600" v-if="errors.enabled">{{ errors.enabled[0] }}</small>
      </div>

      <div class="form-control mt-3">
        <label class="label">
          <span class="label-text font-bold">Thumbnail <span class="text-xs font-thin">(optional)</span> : </span>
        </label>
        <input accept=".jpg,.png,.jpeg,.heic" class="file" type="file" @change="imageChange" ref="imageInput" />
        <small v-if="errors.image" class="text-red-600">{{
            errors.image[0]
        }}</small>
      </div>
      <div v-if="image" class="form-control mt-4">
        <div class="flex flex-wrap justify-center">
          <div class="w-8/12 sm:w-6/12 px-4">
            <img :src="image" alt="..." class="rounded max-w-full h-auto align-middle border-none" />
          </div>
        </div>
      </div>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text font-bold">Categories :</span>
        </label>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 text-center">
          <!--            <div class="flex flex-no-wrap overflow-x-scroll scrolling-touch items-start gap-4">-->
          <div class="truncate" v-for="category in categories" :key="category.id">
            <label class="inline-flex items-center">
              <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="category.id"
                v-model="status.categories">
              <span class="label-text ml-1 md:ml-2">{{ category.name }}</span>
            </label>
          </div>
        </div>
        <small class="text-red-600" v-if="errors.categories">{{ errors.categories[0] }}</small>
      </div>

      <div class="form-control mt-5">
        <label class="label">
          <span class="label-text font-bold">Languages :</span>
        </label>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 text-center">
          <!--            <div class="flex flex-no-wrap overflow-x-scroll scrolling-touch items-start gap-4">-->
          <div class="truncate" v-for="language in languages" :key="language.id">
            <label class="inline-flex items-center">
              <input type="checkbox" checked="checked" class="checkbox checkbox-accent" :value="language.id"
                v-model="status.languages">
              <span class="label-text ml-1 md:ml-2">{{ language.name }}</span>
            </label>
          </div>
        </div>
        <small class="text-red-600" v-if="errors.languages">{{ errors.languages[0] }}</small>
      </div>

      <div class="card p-2 shadow-lg compact bg-base-200 mx-1 mt-5">
        <div class="flex flex-row justify-around">
          <div class="btn btn-success" @click="updateStatus">Update</div>
        </div>
      </div>

    </div>


  </main>
</template>

<script>
import axios from "axios";


export default {
  mounted() {
    this.getStatusInfo();
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      status: {
        allow_comments: 0,
        enabled: 0,
        title: null,
        description: null,
        categories: [],
        languages: [],
        media: {
          id: null,
          url: null,
          ext: null,
        },
      },
      image: null,
      modalInfo: {},
      categories: [],
      languages: [],
      errors: {},
      infoLoading: false,
      isUpdating: false,
      fullImage: false,

    };
  },
  methods: {
    getStatusInfo() {
      this.infoLoading = true;
      let code = this.$route.params.code;
      axios
        .get("/admin/review-details", { params: { code: code } })
        .then(({ data }) => {
          this.status = data.status;
          this.status.languages = data.sl;
          this.status.categories = data.sc;
          this.languages = data.languages;
          this.categories = data.categories;
          this.infoLoading = false;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$router.replace({ name: "notFound" });
          }
          this.infoLoading = false;
        });
    },
    updateStatus() {
      this.isUpdating = true;
      let s = this.status;
      axios.post('/admin/update-status', {
        id: s.id,
        title: s.title,
        description: s.description,
        allow_comments: s.allow_comments,
        enabled: s.enabled,
        categories: s.categories,
        languages: s.languages,
        image: this.image
      })
        .then(() => {
          this.isUpdating = false;
          this.getStatusInfo();
          this.$noti('success', 'Updated Successfully');
          this.image = null;
          this.$refs.imageInput.value = null;
        }).catch(() => {
          this.isUpdating = false;
          this.$noti('error', 'Something went wrong')
        })
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },

    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: { username: username },
      });
    },

    toggleHeight() {
      this.fullImage = !this.fullImage;
    },
  },
};
</script>

<style scoped>
</style>
