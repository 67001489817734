<template>
  <main class="justify-center mx-auto">
    <div class="mx-2 mt-3">
      <div class="flex flex-row w-full justify-between">
        <div
            :class="formType === 1 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(1)"
        >
          Currencies
        </div>
        <div
            :class="formType === 2 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(2)"
        >
          Add Currency
        </div>
      </div>
    </div>

    <div v-if="formType === 1">
      <div class="w-full shadow-2xl">
        <div class="card-body p-1 pb-6">
          <div v-if="isLoading" class="mt-5">
            <content-placeholders :centered="true" :rounded="true">
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
            </content-placeholders>
          </div>
          <div v-else>
            <div v-if="currencies.length" class="mt-5">
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-3">
                <div v-for="(currency, index) in currencies" :key="index">
                  <div
                      class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2"
                  >

                    <div class="flex flex-col justify-around space-y-2">

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Name :</div>
                        <div>{{ currency.name }}</div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Symbol :</div>
                        <div>{{ currency.symbol }}</div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Rate :</div>
                        <div class="flex flex-row space-x-1 items-center">
                          <div>
                            <coin-svg width="15px" height="15px"/>
                          </div>
                          <div>{{ currency.rate }}</div>
                        </div>
                      </div>


                    </div>


                    <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">
                      <div class="btn btn-primary btn-sm" @click="editClickAction(currency)">
                        <i class="bx bx-edit"></i>
                      </div>
                      <div class="btn btn-sm" :class="currency.id === 1 ? 'btn-disabled' : 'btn-primary'"
                           @click="deleteButtonClick(currency.id, currency.name)">
                        <i class="bx bx-trash"></i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div v-else>
              <div
                  class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
              >
                <h2 class="font-bold text-lg">Nothing to Show</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 2">
      <div class="pt-4 w-full justify-center mx-auto md:w-5/6 shadow-2xl">
        <div class="card-body pt-0">
          <form @submit.prevent="createCurrency">

            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Name : </span>
              </label>
              <input v-model="form.name" class="input input-accent" placeholder="Ex: USD" type="text"/>
              <small v-if="errors.name" class="text-red-600">{{ errors.name[0] }}</small>
            </div>

            <div class="form-control mt-2">
              <label class="label">
                <span class="label-text font-bold">Symbol : </span>
              </label>
              <input v-model="form.symbol" class="input input-accent" placeholder="Ex: $" type="text"/>
              <small v-if="errors.symbol" class="text-red-600">{{ errors.symbol[0] }}</small>
            </div>

            <div class="form-control mt-2">
              <div class="label">
                <div class="flex flex-row space-x-1 label-text font-bold">
                  <div>Rate</div>
                  <div class="flex flex-row items-center space-x-1 text-xs font-thin">
                    <div>( x =</div>
                    <div>
                      <coin-svg height="10px" width="10px"/>
                    </div>
                    <div>1 )</div>
                  </div>
                  <div>:</div>
                </div>

              </div>
              <input v-model="form.rate" class="input input-accent" placeholder="Ex: 100" type="number" step="0.01"
                     min="0"/>
              <small v-if="errors.rate" class="text-red-600">{{ errors.rate[0] }}</small>
            </div>


            <div class="form-control mt-8">
              <button :disabled="isCreating" class="btn btn-primary" type="submit">
                <svg-loading v-if="isCreating" class="mr-1" height="16px" width="16px"/>
                Create Currency
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <modal
        :if-close="true"
        :showing="editModal"
        @update:modal="editModal = $event"
    >
      <div
          v-if="editModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Edit Details</div>

        <form @submit.prevent="updateCurrency">

          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Name : </span>
            </label>
            <input v-model="editForm.name" class="input input-accent" placeholder="Ex: USD" type="text"/>
            <small v-if="errors.name" class="text-red-600">{{ errors.name[0] }}</small>
          </div>

          <div class="form-control mt-2">
            <label class="label">
              <span class="label-text font-bold">Symbol : </span>
            </label>
            <input v-model="editForm.symbol" class="input input-accent" placeholder="Ex: $" type="text"/>
            <small v-if="errors.symbol" class="text-red-600">{{ errors.symbol[0] }}</small>
          </div>

          <div class="form-control mt-2">
            <label class="label">
              <span class="label-text font-bold">Rate <small class="text-xs font-thin">(x = 1 coins)</small> : </span>
            </label>
            <input v-model="editForm.rate" class="input input-accent" placeholder="Ex: $" type="number" step="0.01"
                   min="0"/>
            <small v-if="errors.rate" class="text-red-600">{{ errors.rate[0] }}</small>
          </div>


          <div class="form-control mt-8 mb-3">
            <button :disabled="isUpdating" class="btn btn-primary" type="submit">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Update Currency
            </button>
          </div>
        </form>
      </div>
    </modal>

    <modal
        :if-close="true"
        :showing="deleteModal"
        @update:modal="deleteModal = $event"
    >
      <div
          v-if="deleteModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Are You Sure ?</div>
        <div class="text-2xl mt-5">
          Delete : {{ deleteInfo.name }} !<span> </span>
        </div>
        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button
                :disabled="isDeleting"
                class="btn btn-outline"
                @click="deleteModal = false"
            >
              Cancel
            </button>
            <button
                :disabled="isDeleting"
                class="btn btn-error"
                @click="deleteCurrency"
            >
              <svg-loading
                  v-if="isDeleting"
                  class="mr-1"
                  height="16px"
                  width="16px"
              />
              Delete
            </button>
          </div>
        </div>
      </div>
    </modal>

  </main>
</template>

<script>
import {mapActions} from "vuex";
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getCurrencies();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      formType: 1,
      currencies: [],
      form: {
        name: null,
        symbol: null,
        rate: null,
      },
      editForm: {
        id: null,
        name: null,
        symbol: null,
        rate: null,
      },
      deleteInfo: {
        id: null,
        name: null,
      },
      isLoading: false,
      isCreating: false,
      isUpdating: false,
      isDeleting: false,
      errors: {},
      editModal: false,
      deleteModal: false,
    };
  },
  methods: {
    getCurrencies() {
      this.isLoading = true;
      axios
          .get("/admin/currencies")
          .then(({data}) => {
            this.currencies = data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
    },
    tabClick(id) {
      if (this.formType == 1 && id == 1) {
        this.getCurrencies();
      }
      this.formType = id;
    },
    editClickAction(data) {
      try {
        this.editForm = {
          id: data.id,
          name: data.name,
          symbol: data.symbol,
          rate: data.rate,
        };
      } finally {
        this.editModal = true;
      }
    },
    createCurrency() {
      this.isCreating = true;
      this.errors = {};
      axios
          .post("/admin/create-currency", this.form)
          .then(({data}) => {
            this.isCreating = false;
            this.resetForm();
            this.getCurrencies();
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isCreating = false;
            if (error.response.status === 406) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    updateCurrency() {
      this.isUpdating = true;
      this.errors = {};
      axios
          .patch("/admin/update-currency", this.editForm)
          .then(({data}) => {
            this.isUpdating = false;
            this.editForm = {
              id: null,
              name: null,
              symbol: null,
              rate: null,
            };
            this.getCurrencies();
            this.editModal = false;
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isUpdating = false;
            if (error.response.status === 404) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    deleteCurrency() {
      this.isDeleting = true;
      this.errors = {};
      axios
          .post("/admin/delete-currency", {id: this.deleteInfo.id})
          .then(({data}) => {
            this.isDeleting = false;
            this.getCurrencies();
            this.deleteModal = false;
            this.deleteInfo = {};
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isDeleting = false;
            if (error.response.status === 404 || error.response.status === 406 || error.response.status === 400) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    deleteButtonClick(id, name) {
      this.deleteInfo = {id: id, name: name};
      this.deleteModal = true;
    },

    resetForm() {
      this.form = {name: null, image: null, symbol: null, rate: null};
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped></style>