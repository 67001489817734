import axios from "axios";

const UserStore = {
    state: {
        users: {
            current_page: null,
            data: [],
            total: 0,
        },
    },
    getters: {

        users(state) {
            return state.users
        },
    },
    mutations: {
        setUsers(state, data) {
            state.users = data
        },

    },
    actions: {
        async getUsers({commit}, urlData) {
            try {
                let response = await axios.get('/admin/users', urlData);
                commit('setUsers', response.data)
            } catch (e) {
            }
        },

    },


}
export default UserStore;

