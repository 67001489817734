<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Reports</div>
            <div class="btn btn-accent btn-xs" @click="getReports">Refresh</div>
          </div>
          <div class="divider mt-0">Filter</div>

          <div class="flex flex-col md:flex-row justify-around space-y-2 px-2">

            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">SortBy :</div>
              <div>
                <select class="select select-accent select-sm w-full max-w-xs" v-model="filter.sortBy"
                        @change="getReports">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>

            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">Per Page :</div>
              <div>
                <input v-model="filter.per_page" class="input input-accent input-sm md:w-1/2 w-full"
                       placeholder="Show per page"
                       type="number" min="10" @change="getReports"/>
              </div>
            </div>

          </div>


        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="reportData.data">
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3">
              <div v-for="(report, index) in reportData.data" :key="index">
                <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2">

                  <div class="flex flex-col justify-around space-y-2">
                    <div class="avatar self-center mb-2">
                      <div class="w-80 rounded">
                        <figure>
                          <img v-if="report.status.thumbnail" :src="url + '/' + report.status.thumbnail"/>
                          <img v-else :src="url + '/user/no-image.png'"/>
                        </figure>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-5 mx-1">
                      <div class="font-bold">By:</div>
                      <div class="flex flex-row items-center space-x-1">
                        <div class="clickable" @click="viewUser(report.user.username)"> {{ report.user.name }}</div>
                        <div v-if="report.user.verified">
                          <verification-svg height="12px" width="12px"/>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-5 mx-1">
                      <div class="font-bold">Against:</div>
                      <div class="flex flex-row items-center space-x-1">
                        <div class="clickable" @click="viewUser(report.status.user.username)">
                          {{ report.status.user.name }}
                        </div>
                        <div v-if="report.status.user.verified">
                          <verification-svg height="12px" width="12px"/>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-5 mx-1">
                      <div class="font-bold">Created:</div>
                      <div class="badge badge-accent">{{ report.created_at | fromNow }}</div>
                    </div>

                    <div class="flex flex-row justify-between space-x-5 mx-1">
                      <div class="font-bold">Message:</div>
                      <div class="text-right">
                        <h1 v-if="report.message && report.message.length < 40">
                          {{ report.message }}
                        </h1>
                        <h1 v-if="report.message && report.message.length > 40">
                          {{ report.message.substring(0, 40) + "..." }}
                        </h1>
                      </div>
                    </div>

                  </div>

                  <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">

                    <div class="btn btn-primary btn-sm" @click="buttonClick(report, 3)">
                      <i class="bx bx-info-circle"></i>
                    </div>

                    <router-link :to="{name: 'status-details', params:{code: report.status.code}}"
                                 class="btn btn-primary btn-sm">
                      <i class="bx bxs-detail"></i>
                    </router-link>

                    <div class="btn btn-primary btn-sm" @click="buttonClick(report, 1)">
                      <i class="bx bx-envelope"></i>
                    </div>

                    <div class="btn btn-primary btn-sm" @click="buttonClick(report, 2)">
                      <i class="bx bx-trash"></i>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="total == 0">
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="reportData" :limit="3"
                      @pagination-change-page="getReports"></pagination>
        </div>
      </div>
    </div>

    <modal :if-close="true" :showing="replyModal" @update:modal="replyModal = $event">
      <div v-if="replyModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
        <div class="divider">Reply To Report</div>
        <div class="form-control mt-3">
          <div class="flex flex-row justify-between items-center space-x-2">
            <div class="label">To:</div>
            <div>
              <select v-model="replyInfo.user" class="select select-accent">
                <option disabled="disabled" selected="selected" value="">
                  Choose User
                </option>
                <option value="1">{{ reportInfo.user.name }}</option>
                <option value="2">{{ reportInfo.status.user.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-control mt-2">
          <label class="label">
            <span class="label-text">Message : </span>
          </label>
          <textarea class="textarea h-24 textarea-bordered textarea-accent"
                    placeholder="Type to message to reply the user" v-model="replyInfo.replyMsg"></textarea>
        </div>

        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button :disabled="isReplying" class="btn btn-primary" @click="replyReport">
              <svg-loading v-if="isReplying" class="mr-1" height="16px" width="16px"/>
              Reply
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal :if-close="true" :showing="detailsModal" @update:modal="detailsModal = $event">
      <div v-if="detailsModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
        <div class="divider">Report Details</div>
        <div class="flex flex-col justify-around space-y-2">
          <div class="avatar self-center mb-2">
            <div class="w-80 rounded">
              <figure>
                <img v-if="reportInfo.status.thumbnail" :src="url + '/' + reportInfo.status.thumbnail"/>
                <img v-else :src="url + '/user/no-image.png'"/>
              </figure>
            </div>
          </div>

          <div class="flex flex-row justify-between space-x-5 mx-1">
            <div class="font-bold">By:</div>
            <div class="flex flex-row items-center space-x-1">
              <div> {{ reportInfo.user.name }}</div>
              <div v-if="reportInfo.user.verified">
                <verification-svg height="12px" width="12px"/>
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-between space-x-5 mx-1">
            <div class="font-bold">Against:</div>
            <div class="flex flex-row items-center space-x-1">
              <div> {{ reportInfo.status.user.name }}</div>
              <div v-if="reportInfo.status.user.verified">
                <verification-svg height="12px" width="12px"/>
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-between space-x-5 mx-1">
            <div class="font-bold">Created:</div>
            <div class="badge badge-accent">{{ reportInfo.created_at | fromNow }}</div>
          </div>

          <div class="flex flex-col space-x-2 mx-1 text-left">
            <div class="font-bold">Status Title :</div>
            <div>{{ reportInfo.status.title }}</div>
          </div>

          <div class="flex flex-col space-x-2 mx-1 text-left">
            <div class="font-bold">Message:</div>
            <div>{{ reportInfo.message }}</div>
          </div>

        </div>
        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button class="btn btn-outline" @click="detailsModal = false">
              Close
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal :if-close="true" :showing="deleteModal" @update:modal="deleteModal = $event">
      <div v-if="deleteModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
        <div class="divider">Are You Sure ?</div>
        <div class="text-2xl mt-5">
          Delete : Report from {{ reportInfo.user.name }} against {{ reportInfo.status.user.name }} !<span> </span>
        </div>
        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button :disabled="isDeleting" class="btn btn-outline" @click="deleteModal = false">
              Cancel
            </button>
            <button :disabled="isDeleting" class="btn btn-error" @click="deleteReport">
              <svg-loading v-if="isDeleting" class="mr-1" height="16px" width="16px"/>
              Delete
            </button>
          </div>
        </div>
      </div>
    </modal>

  </main>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    if (!this.reportData.data.length) {
      this.getReports();
    }
    this.$Progress.finish();
  },

  data() {
    return {
      url: this.$config.FILE_URL,
      total: null,
      filter: {
        type: '10',
        per_page: 12,
        sortBy: 'desc',
      },
      reportInfo: {
        id: null,
      },
      replyInfo: {
        user: "1",
        replyMsg: null,
      },
      replyModal: false,
      isReplying: false,
      detailsModal: false,
      deleteModal: false,
      isDeleting: false,
      isLoading: false,
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      reportData: 'reports',
    })
  },
  methods: {
    ...mapActions({
      getReportData: 'getReports'
    }),
    getReports(page) {
      this.isLoading = true;
      let type = this.filter.type;
      if (type !== '') {
        if (type == 10) {
          type = ''
        }
      }
      let sortBy = this.filter.sortBy;
      let cp = page ? page : this.reportData.current_page;
      let urlData = {params: {type: type, sortBy: sortBy, per_page: this.filter.per_page, page: cp}};
      this.getReportData(urlData).then(() => {
        this.total = this.reportData.total;
        this.isLoading = false;
      })
          .catch(() => {
            this.isLoading = false;
          });
    },
    buttonClick(data, type) {
      this.reportInfo = data;
      if (type == 1) {
        this.replyModal = true;
      } else if (type == 2) {
        this.deleteModal = true;
      } else if (type == 3) {
        this.detailsModal = true;
      }
    },
    getUID() {
      if (this.replyInfo.user == 1) {
        return this.reportInfo.user.id;
      } else if (this.replyInfo.user == 2) {
        return this.reportInfo.status.user.id;
      }
    },
    replyReport() {
      this.isReplying = true;
      this.errors = {};
      let u_id = this.getUID();
      axios.post("/admin/report-reply", {u_id: u_id, message: this.replyInfo.replyMsg})
          .then(({data}) => {
            this.isReplying = false;
            this.replyInfo.replyMsg = null;
            this.replyModal = false;
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isReplying = false;
            if (error.response.status === 404 || error.response.status === 406) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    deleteReport() {
      this.isDeleting = true;
      this.errors = {};
      axios
          .post("/admin/report-delete", {id: this.reportInfo.id})
          .then(({data}) => {
            this.isDeleting = false;
            this.getReports();
            this.deleteModal = false;
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isDeleting = false;
            if (error.response.status === 404 || error.response.status === 406) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: {username: username},
      });
    },

  },
};
</script>

<style scoped></style>